.testimonial-session-wrapper{
    background-color: #ffffff;
    padding: 40px;
    text-align: center;
    /* background-image: url("/images/parallax_04.jpg");
    background-position: fixed;
    background-size: cover;
    opacity: 0.4; */
}

h1{
    font-weight: 600;
    margin-top: 16px;
}

.cards {
    min-width: 300px;
    text-align: center;
    margin: 4px;
    padding: 5px;
}

.testimonial-body{
    display: flex;
    flex-direction: row;
}

.description {
    margin:40px;
    border: 1px solid #ffad00;
    padding: 12px;
    position: relative;
    border-radius: 30px;
}

.description::after{
    position: absolute;
    content: " ";
    border-right: 1px solid #ffad00;
    border-top: 1px solid #ffad00;
    height: 24px;
    width: 20px;    
    transform-origin: left;
    transform: translate(-50%, 0) skewY(-50deg);
    top:0px;
    left: 50%;
    background-color: white;
}

.testimonial-img{
    border-radius: 50%;
    border: 3px solid #ffad00;
    height: 100px;
}

.carousel-indicators li{
    background-color: #ffad00;
}