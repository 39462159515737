.quick-filters {
  display: flow-root;
}

.pageNation {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.page {
  display: inline-block;
  cursor: pointer;
}

.page-item .active {
  text-decoration: underline !important;
  background: beige;
  box-shadow: none;
}
