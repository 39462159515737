.register-wrapper {
    width: 100% !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.register-div {
    width: 60%;
    border: 3px solid #f5f5ff;
    background-color: #f5f5ff;
    padding-bottom: 3em;
    margin-top: 3em;
    margin-bottom: 3em;
}

#patternDiv{
    position: absolute;
    top: 5px;
    right: 12px;
    z-index:20000000000000000000000000000000000;
}