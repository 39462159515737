@import url(https://colorlib.com/preview/theme/etrain/css/flaticon.css);
@import url(https://colorlib.com/preview/theme/etrain/css/slick.css);
@import url(https://colorlib.com/preview/theme/etrain/css/themify-icons.css);
@import url(https://colorlib.com/preview/theme/etrain/css/owl.carousel.min.css);
@import url(https://colorlib.com/preview/theme/etrain/css/magnific-popup.css);
@import url(https://colorlib.com/preview/theme/etrain/css/animate.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:800|Poppins:300,400,500,600,700,800|Roboto:300,400,500);
body {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}
.login-div {
    text-align: center;
    padding: 5px;
}

#popup-div > div > div > div {
    /* use transparent insted for complete transparent background */
    background-color: rgba(239, 238, 238, 0.5) !important;
}

.hide {
    display: none;
}

.wrapper{
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: auto;
    margin: -4em auto !important;
}

.login-div form {
    margin-top: 5em !important;
}

.close-button{
    position: relative;
    right: 20px;
    top: 0;
    font-size: 20px;
    font-weight: bold;
    color: #e65349;
    cursor: pointer;
    float: right;
    z-index: 20000000;
}

.close-button:hover{
    color: #f31d0f;
}

@media screen and (max-width : 600px){
    .wrapper{
        width: 80%;
    }
}

.welcome-wrapper h2{
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
}

.welcome-wrapper i {
    font-size: 7em;
    background-color: white;
    border-radius: 50%;
    padding: 7px;
    color: #546286;
}

.welcome-wrapper{
    margin: 30px;
}


.input-field-wrapper{
    position: relative;
    margin: 30px;
}

.input-field-wrapper input{
    border: none;
    border-bottom: 1px solid gray;
    background:none;
    z-index: 20;
    position: relative;
}

#userName:active, #userName:focus ~ .lable{
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#pwd:active, #pwd:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#firstName:active, #firstName:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#lastName:active, #lastName:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#uName:active, #uName:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#password:active, #password:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#cpassword:active, #cpassword:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#mobileNumber:active, #mobileNumber:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

input[value]:not([value=""]) ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

.lable{
    position: absolute;
    top:50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 2;
}

@-webkit-keyframes movetop {
    from{
        top: 50%;
    }
    to {
        top: -15px;
    }
}

@keyframes movetop {
    from{
        top: 50%;
    }
    to {
        top: -15px;
    }
}

.submit-wrapper {
    padding-left: 30px;
    padding-right: 30px;
}

.submit-wrapper label{
    float: left;
}

.submit-wrapper button {
    float: right;
}

.inputFieldError {
    border-bottom: 4px solid red !important;
}

.inputFieldSuccess{
    border-bottom: 1px solid green !important;
}

.form-control:active, .form-control:focus{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.login-btn{
    float: right;
}

.login-wrapper {
    background-color: white;
    border-radius: 1rem;
    margin-top: -92px;
    min-height: 280px;
}

.become-member {
    width: 100%;
    padding: 0;
    height: auto;
    position: relative;
    display: inline-block;
}

.register-wrapper-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.register-wrapper-header img {
    height: 35px;
}

.teacher-sample-demo{
    display: none;
}

.our-team:hover .teacher-sample-demo{
    display:block;
}

.request-demo-form-wrapper {
    width: 35%;
    height: auto;
    background: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
}

/* for Requestdemo input field animation */

#reqFullName:active, #reqFullName:focus ~ .lable{
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#reqEmail:active, #reqEmail:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#reqMobileNumber:active, #reqMobileNumber:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

#reqCourses:active, #reqCourses:focus ~ .lable {
    top: -15px;
    -webkit-animation: movetop;
            animation: movetop;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
}

/* remove previous and next buttons on home carosal */

@media screen and (max-width: 640px){
    .new-effect {
        display: none !important;
    }
}

.entities{
    color: red;
    padding: 0 5px;
    border: 1px solid red;
    margin: 0 -3px;
    border-radius: 50%;
}

.entitiesVallid {
    border: 1px solid green !important;
    color: green !important;
}


.swal2-container {
    z-index: 1000000 !important;
}
.testimonial-session-wrapper{
    background-color: #ffffff;
    padding: 40px;
    text-align: center;
    /* background-image: url("/images/parallax_04.jpg");
    background-position: fixed;
    background-size: cover;
    opacity: 0.4; */
}

h1{
    font-weight: 600;
    margin-top: 16px;
}

.cards {
    min-width: 300px;
    text-align: center;
    margin: 4px;
    padding: 5px;
}

.testimonial-body{
    display: flex;
    flex-direction: row;
}

.description {
    margin:40px;
    border: 1px solid #ffad00;
    padding: 12px;
    position: relative;
    border-radius: 30px;
}

.description::after{
    position: absolute;
    content: " ";
    border-right: 1px solid #ffad00;
    border-top: 1px solid #ffad00;
    height: 24px;
    width: 20px;    
    transform-origin: left;
    transform: translate(-50%, 0) skewY(-50deg);
    top:0px;
    left: 50%;
    background-color: white;
}

.testimonial-img{
    border-radius: 50%;
    border: 3px solid #ffad00;
    height: 100px;
}

.carousel-indicators li{
    background-color: #ffad00;
}
.quick-filters {
  display: flow-root;
}

.pageNation {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.page {
  display: inline-block;
  cursor: pointer;
}

.page-item .active {
  text-decoration: underline !important;
  background: beige;
  box-shadow: none;
}

body {
    background-color: #f9f9fa
}

.flex {
    flex: 1 1 auto
}

@media (max-width:991.98px) {
    .padding {
        padding: 1.5rem
    }
}

@media (max-width:767.98px) {
    .padding {
        padding: 1rem
    }
}

.padding {
    padding: 5rem
}

.card {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none
}


.rounded,
.loader-demo-box {
    border-radius: 0.25rem !important
}

.loader-demo-box {
    width: 100%;
    height: 200px
}

.jumping-dots-loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto
}

.jumping-dots-loader span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: rgb(255 173 0);
    margin: 35px 5px
}

.jumping-dots-loader span:nth-child(1) {
    -webkit-animation: bounce 1s ease-in-out infinite;
            animation: bounce 1s ease-in-out infinite
}

.jumping-dots-loader span:nth-child(2) {
    -webkit-animation: bounce 1s ease-in-out 0.33s infinite;
            animation: bounce 1s ease-in-out 0.33s infinite
}

.jumping-dots-loader span:nth-child(3) {
    -webkit-animation: bounce 1s ease-in-out 0.66s infinite;
            animation: bounce 1s ease-in-out 0.66s infinite
}

@-webkit-keyframes bounce {

    0%,
    75%,
    100% {
        transform: translateY(0)
    }

    25% {
        transform: translateY(-20px)
    }
}

@keyframes bounce {

    0%,
    75%,
    100% {
        transform: translateY(0)
    }

    25% {
        transform: translateY(-20px)
    }
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item p {
  width: 80%;
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #5d5c5c;
}

.branchName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #ffad00;
  text-decoration: underline;
}

.icon {
  padding: 4px;
  background-color: #ffad00;
  border-radius: 50%;
  color: white;
  margin: 20px;
  width: 35px;
  height: 35px;
  text-align: center;
  align-items: center;
  display: inline-block;
}

/**************** extend css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_extends.scss */
.btn_1, .btn_2:hover, .main_menu .navbar-toggler:after, .banner_part .banner_text .btn_1, .feature_part .single_feature:hover span {
  background-image: linear-gradient(to left, #ee390f 0%, #f9b700 51%, #ee390f 100%);
}

/* line 6, E:/172 Etrain Education/172_Etrain_Education_html/sass/_extends.scss */
.member_counter {
  background-image: linear-gradient(0deg, #ee390f 0%, #f9b700 100%);
  background-image: -ms-linear-gradient(0deg, #ee390f 0%, #f9b700 100%);
}

/**************** extend css start ****************/
/**************** common css start ****************/
/* line 3, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

/* line 9, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.message_submit_form:focus {
  outline: none;
}

/* line 12, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
input:hover, input:focus {
  outline: none !important;
}

/* line 15, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.gray_bg {
  background-color: #f7f7f7;
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.section_padding {
  padding: 140px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_padding {
    padding: 80px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

@media (max-width: 576px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_padding {
    padding: 70px 0px;
  }
}

/* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.single_padding_top {
  padding-top: 140px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .single_padding_top {
    padding-top: 70px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .single_padding_top {
    padding-top: 70px !important;
  }
}

@media (max-width: 576px) {
  /* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .single_padding_top {
    padding-top: 70px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .single_padding_top {
    padding-top: 80px !important;
  }
}

/* line 48, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.padding_top {
  padding-top: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 48, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .padding_top {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 48, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

@media (max-width: 576px) {
  /* line 48, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 48, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

/* line 63, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
a {
  text-decoration: none;
  transition: 0.5s;
}

/* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
a:hover {
  outline: none;
  text-decoration: none;
}

/* line 72, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
h1, h2, h3, h4, h5, h6 {
  color: #0c2e60;
  font-family: "Poppins", sans-serif;
}

/* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
p {
  color: #666666;
  font-family: "Roboto", sans-serif;
  line-height: 1.929;
  font-size: 14px;
  margin-bottom: 0px;
  color: #888888;
}

/* line 85, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
h2 {
  font-size: 44px;
  line-height: 28px;
  color: #0c2e60;
  font-weight: 600;
  line-height: 1.222;
}

@media (max-width: 576px) {
  /* line 85, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 85, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  h2 {
    font-size: 24px;
    line-height: 25px;
  }
}

/* line 102, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
h3 {
  font-size: 24px;
  line-height: 25px;
}

@media (max-width: 576px) {
  /* line 102, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  h3 {
    font-size: 20px;
  }
}

/* line 111, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
h5 {
  font-size: 18px;
  line-height: 22px;
}

/* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
img {
  max-width: 100%;
}

/* line 119, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
a:focus, .button:focus, button:focus, .btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  transition: 1s;
}

/* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.section_tittle {
  margin-bottom: 110px;
}

@media (max-width: 576px) {
  /* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle {
    margin-bottom: 50px;
  }
}

/* line 140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.section_tittle h2 {
  font-size: 42px;
  color: #0c2e60;
  line-height: 37px;
  font-weight: 700;
  position: relative;
  position: relative;
}

/* line 147, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.section_tittle h2:after {
  position: absolute;
  content: "";
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 80px;
  height: 2px;
  background-color: #ff663b;
}

@media (max-width: 576px) {
  /* line 147, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2:after {
    bottom: -14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 147, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2:after {
    bottom: -14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 147, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2:after {
    bottom: -14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 147, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2:after {
    bottom: -14px;
  }
}

@media (max-width: 576px) {
  /* line 140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle h2 {
    font-size: 35px;
    line-height: 40px;
  }
}

/* line 189, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.section_tittle p {
  color: #556172;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 11px;
  margin-bottom: 26px;
}

@media (max-width: 576px) {
  /* line 189, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 189, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 189, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 189, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .section_tittle p {
    margin-bottom: 10px;
  }
}

/* line 209, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* line 214, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.mb_110 {
  margin-bottom: 110px;
}

@media (max-width: 576px) {
  /* line 214, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mb_110 {
    margin-bottom: 220px;
  }
}

/* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.mt_130 {
  margin-top: 130px;
}

@media (max-width: 576px) {
  /* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mt_130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mt_130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mt_130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mt_130 {
    margin-top: 70px;
  }
}

/* line 236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.mb_130 {
  margin-bottom: 130px;
}

@media (max-width: 576px) {
  /* line 236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mb_130 {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mb_130 {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mb_130 {
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .mb_130 {
    margin-bottom: 70px;
  }
}

/* line 251, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.padding_less_40 {
  margin-bottom: -50px;
}

/* line 254, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
.z_index {
  z-index: 9 !important;
  position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 3640px) {
  /* line 260, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  .container {
    max-width: 1170px;
  }
}

@media (max-width: 1200px) {
  /* line 265, E:/172 Etrain Education/172_Etrain_Education_html/sass/_common.scss */
  [class*="hero-ani-"] {
    display: none !important;
  }
}

/**************** common css end ****************/
/* Main Button Area css
============================================================================================ */
/* line 3, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.submit_btn {
  width: auto;
  display: inline-block;
  background: #fff;
  padding: 0px 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #edeff2;
  cursor: pointer;
  transition: 0.5s;
}

/* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.submit_btn:hover {
  background: transparent;
}

/* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_1 {
  display: inline-block;
  padding: 13.5px 45px;
  border-radius: 50px;
  font-size: 14px;
  color: #fff;
  transition: all .4s ease-in-out;
  text-transform: capitalize;
  background-size: 200% auto;
  border: 1px solid transparent;
  box-shadow: 0px 12px 20px 0px rgba(255, 126, 95, 0.15);
}

/* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_1:hover {
  color: #fff !important;
  background-position: right center;
  box-shadow: 0px 10px 30px 0px rgba(193, 34, 10, 0.2);
}

@media (max-width: 576px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_1 {
    padding: 10px 30px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_1 {
    padding: 10px 30px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_1 {
    padding: 10px 30px;
    margin-top: 30px;
  }
}

/* line 60, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_2 {
  display: inline-block;
  padding: 13px 39px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #0c2e60;
  color: #0c2e60;
  font-size: 14px;
  transition: all .4s ease-in-out;
  text-transform: capitalize;
  background-size: 200% auto;
  font-size: 15px;
  font-weight: 500;
}

/* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_2:hover {
  color: #fff !important;
  background-position: right center;
  border: 1px solid transparent;
}

@media (max-width: 576px) {
  /* line 60, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_2 {
    padding: 14px 30px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 60, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_2 {
    padding: 14px 30px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 60, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .btn_2 {
    padding: 14px 30px;
    margin-top: 30px;
  }
}

/* line 97, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_4 {
  background-color: #ff663b;
  padding: 3.5px 21px;
  background: #ff663b;
  text-transform: capitalize;
  display: inline-block;
  border: 2px solid transparent;
  transition: 0.5s;
  color: #fff;
}

/* line 106, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_4:hover {
  background: rgba(255, 102, 59, 0.8);
  color: #fff;
}

/*=================== custom button rule start ====================*/
/* line 114, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 54px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #edeff2;
  text-transform: uppercase;
  background-color: #ff663b;
  cursor: pointer;
  transition: 0.5s;
}

@media (max-width: 767px) {
  /* line 114, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
  .button {
    font-size: 13px;
    padding: 9px 24px;
  }
}

/* line 133, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button:hover {
  color: #fff;
}

/* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button-link {
  letter-spacing: 0;
  color: #3b1d82;
  border: 0;
  padding: 0;
}

/* line 144, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button-link:hover {
  background: transparent;
  color: #3b1d82;
}

/* line 150, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button-header {
  color: #fff;
  border-color: #edeff2;
}

/* line 154, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button-header:hover {
  background: #b8024c;
  color: #fff;
}

/* line 160, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.button-contactForm {
  color: #fff;
  border-color: #edeff2;
  padding: 12px 25px;
}

/* End Main Button Area css
============================================================================================ */
/* Start Blog Area css
============================================================================================ */
/* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.latest-blog-area .area-heading {
  margin-bottom: 70px;
}

/* line 10, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_area a {
  color: #666666 !important;
  text-decoration: none;
  transition: 0.5s;
}

/* line 14, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_area a:hover, .blog_area a :hover {
  color: #ff663b;
}

/* line 20, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog {
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog:hover {
  box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
}

/* line 28, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .thumb {
  overflow: hidden;
  position: relative;
}

/* line 32, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .thumb:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: 0.5s;
}

/* line 45, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog h4 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 34px;
  margin-bottom: 25px;
}

/* line 52, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 62, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .date {
  color: #888;
  text-align: left;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

/* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .tag {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 300;
  margin-right: 22px;
  position: relative;
}

/* line 80, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .tag:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 10px;
  background: #acacac;
  right: -12px;
  top: 7px;
}

@media (max-width: 1199px) {
  /* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog .tag {
    margin-right: 8px;
  }
  /* line 94, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog .tag:after {
    display: none;
  }
}

/* line 100, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .likes {
  margin-right: 16px;
}

@media (max-width: 800px) {
  /* line 20, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog {
    margin-bottom: 30px;
  }
}

/* line 108, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .single-blog-content {
  padding: 30px;
}

/* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .single-blog-content .meta-bottom p {
  font-size: 13px;
  font-weight: 300;
}

/* line 117, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog .single-blog-content .meta-bottom i {
  color: #edeff2;
  font-size: 13px;
  margin-right: 7px;
}

@media (max-width: 1199px) {
  /* line 108, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog .single-blog-content {
    padding: 15px;
  }
}

/* line 131, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-blog:hover .thumb:after {
  opacity: .7;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  /* line 139, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog h4 {
    transition: all 300ms linear 0s;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
  /* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-blog h4 a {
    font-size: 18px;
  }
}

/* line 153, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog {
  position: relative;
}

/* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog .single-blog-content {
  position: absolute;
  left: 35px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

@media (min-width: 992px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .full_image.single-blog .single-blog-content {
    bottom: 100px;
  }
}

/* line 175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog h4 {
  transition: 0.5s;
  border-bottom: none;
  padding-bottom: 5px;
}

/* line 181, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 191, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog .date {
  color: #fff;
}

/* line 196, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.full_image.single-blog:hover .single-blog-content {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
/* line 220, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

/* line 224, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.l_blog_item .l_blog_text .date a {
  font-size: 12px;
}

/* line 230, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  transition: 0.5s;
}

/* line 243, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
/* line 259, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

/* line 263, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

/* line 270, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

/* line 281, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item {
  background: #fff;
}

/* line 284, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_img {
  position: relative;
}

/* line 287, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

/* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* line 303, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

/* line 316, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

/* line 319, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

/* line 332, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* line 342, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* line 354, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
/* line 370, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.latest_blog_area {
  background: #f9f9ff;
}

/* line 374, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post {
  margin-bottom: 30px;
}

/* line 377, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post .thumb {
  overflow: hidden;
}

/* line 380, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

/* line 385, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post .details {
  padding-top: 30px;
}

/* line 388, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

/* line 399, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* line 406, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

/* line 413, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
}

/* line 427, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
/* line 434, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 900px) {
  /* line 434, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_categorie_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1100px) {
  /* line 434, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_categorie_area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* line 450, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* line 455, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post img {
  max-width: 100%;
}

/* line 459, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.75);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 472, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

/* line 490, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

/* line 497, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

/* line 506, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.categories_post:hover .categories_details {
  background: rgba(222, 99, 32, 0.85);
}

/*============ blog_left_sidebar css ==============*/
/* line 521, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_item {
  margin-bottom: 50px;
}

/* line 525, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  /* line 525, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_details {
    padding: 60px 30px 35px 35px;
  }
}

/* line 533, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_details p {
  margin-bottom: 30px;
}

/* line 537, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_details a {
  color: #ff8b23;
}

/* line 540, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_details a:hover {
  color: #ff663b !important;
}

/* line 545, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  /* line 545, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* line 559, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link li {
  float: left;
  font-size: 14px;
}

/* line 563, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link li a {
  color: #999999;
}

/* line 567, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link li i,
.blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

/* line 573, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

/* line 579, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link li:last-child::after {
  display: none;
}

/* line 584, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

/* line 592, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_item_img {
  position: relative;
}

/* line 595, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background-color: #ff7e5f;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  /* line 595, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

/* line 611, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  /* line 611, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

/* line 623, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 623, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

/* line 643, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

/* line 648, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}

/* line 656, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

/* line 665, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

/* line 674, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .search_widget .form-control::-webkit-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .search_widget .form-control:-ms-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .search_widget .form-control::-ms-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .search_widget .form-control::placeholder {
  color: #999999;
}

/* line 678, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .search_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 687, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 694, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 706, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
}

/* line 715, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .newsletter_widget .form-control:-ms-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .newsletter_widget .form-control::-ms-input-placeholder {
  color: #999999;
}
.blog_right_sidebar .newsletter_widget .form-control::placeholder {
  color: #999999;
}

/* line 719, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 728, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 735, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 748, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

/* line 753, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
  border-bottom: 0;
}

/* line 757, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

/* line 762, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

/* line 767, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

/* line 784, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

/* line 789, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

/* line 800, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
  color: #fff;
}

/* line 806, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

/* line 813, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

/* line 821, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

/* line 824, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 4px 20px;
  margin-bottom: 8px;
  margin-right: 3px;
  transition: all 0.3s ease 0s;
  color: #888888;
  font-size: 13px;
}

/* line 835, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #ff663b;
  color: #fff !important;
  -webkit-text-fill-color: #fff;
  text-decoration: none;
  transition: 0.5s;
}

/* line 850, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

/* line 856, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

/* line 956, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

/* line 977, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-pagination {
  margin-top: 80px;
}

/* line 981, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}

/* line 998, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-pagination .page-link i,
.blog-pagination .page-link span {
  font-size: 13px;
}

/* line 1010, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

/* line 1017, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

/*============ Start Blog Single Styles  =============*/
/* line 1042, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

/* line 1047, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .social-links {
  padding-top: 10px;
}

/* line 1050, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

/* line 1054, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

/* line 1067, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog_details {
  padding-top: 26px;
}

/* line 1070, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

/* line 1080, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  /* line 1080, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .quote-wrapper {
    padding: 30px;
  }
}

/* line 1094, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid;
}

@media (min-width: 768px) {
  /* line 1094, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .quotes {
    padding: 25px 25px 25px 30px;
  }
}

/* line 1104, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .arrow {
  position: absolute;
}

/* line 1107, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

/* line 1114, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* line 1119, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff;
}

/* line 1123, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top p {
  margin-bottom: 0;
}

/* line 1127, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .like-info {
  font-size: 14px;
}

/* line 1130, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .comment-count {
  font-size: 14px;
}

/* line 1140, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1149, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li {
  display: inline-block;
  margin-right: 15px;
}

/* line 1153, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li:last-child {
  margin: 0;
}

/* line 1157, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
  font-size: 14px;
  color: #999999;
}

/* line 1175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px;
}

@media (max-width: 600px) {
  /* line 1175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .blog-author {
    padding: 20px 8px;
  }
}

/* line 1184, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 30px;
}

@media (max-width: 600px) {
  /* line 1184, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .blog-author img {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }
}

/* line 1197, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author a {
  display: inline-block;
}

/* line 1201, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author a:hover {
  color: #ff663b;
}

/* line 1206, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author p {
  margin-bottom: 0;
  font-size: 15px;
}

/* line 1211, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .blog-author h4 {
  font-size: 16px;
}

/* line 1218, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px;
}

/* line 1223, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

/* line 1227, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
}

/* line 1233, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left {
  text-align: left;
}

/* line 1236, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

/* line 1240, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb img {
  transition: 0.5s;
}

/* line 1245, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  transition: 0.5s;
}

/* line 1252, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

/* line 1257, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  /* line 1233, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

/* line 1268, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right {
  text-align: right;
}

/* line 1271, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

/* line 1275, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb img {
  transition: 0.5s;
}

/* line 1280, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  transition: 0.5s;
}

/* line 1287, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

/* line 1292, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  /* line 1300, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

/* line 1307, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

@media (max-width: 414px) {
  /* line 1307, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comments-area {
    padding: 50px 8px;
  }
}

/* line 1318, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area h4 {
  margin-bottom: 35px;
  font-size: 18px;
}

/* line 1325, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

/* line 1334, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .comment-list {
  padding-bottom: 48px;
}

/* line 1337, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

/* line 1341, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  /* line 1347, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  /* line 1351, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  /* line 1355, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

/* line 1362, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .thumb {
  margin-right: 20px;
}

/* line 1365, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

/* line 1371, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

/* line 1378, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
}

/* line 1384, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

/* line 1401, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* line 1409, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .form-group {
  margin-bottom: 30px;
}

/* line 1413, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form h4 {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
}

/* line 1421, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  /* line 1421, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/* line 1430, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  /* line 1430, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
  .comment-form .email {
    padding-left: 0px;
  }
}

/* line 1438, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 1446, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 1451, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.comment-form .form-control:-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.comment-form .form-control::-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.comment-form .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 1456, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form .form-control::-webkit-input-placeholder {
  color: #777777;
}
.comment-form .form-control:-ms-input-placeholder {
  color: #777777;
}
.comment-form .form-control::-ms-input-placeholder {
  color: #777777;
}
.comment-form .form-control::placeholder {
  color: #777777;
}

/* line 1461, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form textarea {
  padding-top: 18px;
  border-radius: 12px;
  height: 100% !important;
}

/* line 1467, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

/* line 1473, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

/* line 1479, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

/* line 1485, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog.scss */
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/* line 4, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .desc a {
  font-size: 16px;
  color: #232b2b !important;
}

/* line 10, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content {
  padding: 38px 38px 23px;
  border: 0px solid #edeff2;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

/* line 14, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content p {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content p a {
  color: #ff663b;
}

/* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.481;
  margin-bottom: 16px;
}

/* line 28, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content h5 {
  font-size: 15px;
  color: #8a8a8a;
  font-weight: 400;
}

/* line 33, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content .list-unstyled {
  margin-top: 33px;
}

/* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content .list-unstyled li {
  display: inline;
  margin-right: 17px;
  color: #999999;
}

/* line 39, E:/172 Etrain Education/172_Etrain_Education_html/sass/_single_blog.scss */
.single_blog_post .single_blog .single_appartment_content .list-unstyled li a {
  margin-right: 8px;
  color: #999999;
}

/**************menu part start*****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

/* line 8, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .navbar-brand {
  padding: 0rem !important;
}

/* line 12, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .navbar {
  padding: 20px 0px;
}

/* line 15, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .navbar-toggler {
  position: relative;
  z-index: 1;
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .navbar-toggler:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: 200% auto;
}

/* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .main-menu-item {
  text-align: right;
  justify-content: right;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item {
    padding-left: 25px;
  }
}

/* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .main-menu-item ul li .nav-link {
  color: #0c2e60;
  font-size: 15px;
  padding: 0px 24px;
  font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item ul li .nav-link {
    padding: 0px 16px;
  }
}

/* line 46, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .main-menu-item ul li .nav-link:hover {
  color: #ee390f;
}

/* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.main_menu .main-menu-item ul .btn_1 {
  color: #fff;
  font-size: 14px;
  padding: 11.5px 33px;
  margin-left: 41px;
}

/* line 67, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15) !important;
  background-color: #fafafa;
}

/* line 73, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.dropdown .dropdown-menu {
  transition: all 0.5s;
  overflow: hidden;
  transform-origin: top center;
  transform: scale(1, 0);
  display: block;
  margin-top: 32px;
}

/* line 80, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 9px 18px !important;
  color: #000 !important;
}

/* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff663b !important;
}

/* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.dropdown:hover .dropdown-menu {
  transform: scale(1);
}

@media (max-width: 576px) {
  /* line 99, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .single_page_menu .navbar-collapse ul li .nav-link {
    color: #000 !important;
  }
  /* line 107, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
  /* line 110, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-collapse {
    z-index: 9999 !important;
    position: absolute;
    left: 0;
    top: 71px;
    width: 100%;
    background-color: #fff;
    text-align: center !important;
  }
  /* line 120, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item {
    text-align: left !important;
  }
  /* line 122, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item {
    padding: 5px 15px !important;
  }
  /* line 124, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item a {
    padding: 5px 15px !important;
  }
  /* line 129, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-nav {
    align-items: start !important;
  }
  /* line 133, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-menu {
    transform: scale(1, 0);
    display: none;
    margin-top: 10px;
  }
  /* line 139, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown:hover .dropdown-menu {
    transform: scale(1);
    display: block;
    color: #ff663b;
  }
  /* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-item:hover {
    color: #ff663b !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 155, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .single_page_menu .navbar-collapse ul li .nav-link {
    color: #000 !important;
  }
  /* line 162, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
  /* line 165, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-collapse {
    z-index: 9999 !important;
    position: absolute;
    left: 0;
    top: 71px;
    width: 100%;
    background-color: #fff;
    text-align: center !important;
  }
  /* line 175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item {
    text-align: left !important;
  }
  /* line 177, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item {
    padding: 10px 15px !important;
  }
  /* line 179, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item a {
    padding: 5px 15px !important;
  }
  /* line 184, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-nav {
    align-items: start !important;
  }
  /* line 188, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-menu {
    transform: scale(1, 0);
    display: none;
    margin-top: 10px;
  }
  /* line 194, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown:hover .dropdown-menu {
    transform: scale(1);
    display: block;
  }
  /* line 199, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-item:hover {
    color: #ff663b !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 209, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .single_page_menu .navbar-collapse ul li .nav-link {
    color: #000 !important;
  }
  /* line 216, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
  /* line 219, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-collapse {
    z-index: 9999 !important;
    position: absolute;
    left: 0;
    top: 71px;
    width: 100%;
    background-color: #fff;
    text-align: center !important;
  }
  /* line 229, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item {
    text-align: left !important;
  }
  /* line 231, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item {
    padding: 10px 15px !important;
  }
  /* line 233, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .main_menu .main-menu-item .nav-item a {
    padding: 5px 15px !important;
  }
  /* line 238, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .navbar-nav {
    align-items: start !important;
  }
  /* line 242, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-menu {
    transform: scale(1, 0);
    display: none;
    margin-top: 10px;
  }
  /* line 248, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown:hover .dropdown-menu {
    transform: scale(1);
    display: block;
    color: #ff663b;
  }
  /* line 254, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .dropdown .dropdown-item:hover {
    color: #ff663b !important;
  }
}

/* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.single_page_menu .logo_2 {
  display: none;
}

/* line 264, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.single_page_menu .logo_1 {
  display: inherit;
}

/* line 270, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.single_page_menu .main-menu-item ul li .nav-link {
  color: #fff;
  font-size: 15px;
  padding: 0px 24px;
  font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 270, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
  .single_page_menu .main-menu-item ul li .nav-link {
    padding: 0px 16px;
  }
}

/* line 284, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.menu_fixed {
  position: fixed;
  z-index: 9999 !important;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  top: 0;
}

/* line 291, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.menu_fixed .logo_2 {
  display: inherit;
}

/* line 294, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.menu_fixed .logo_1 {
  display: none;
}

/* line 299, E:/172 Etrain Education/172_Etrain_Education_html/sass/_menu.scss */
.menu_fixed .main-menu-item ul li .nav-link {
  color: #000;
}

/**************** banner part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part {
  height: 880px;
  position: relative;
  overflow: hidden;
  background-image: url(/images/course-detail/banner_bg.png);
  background-repeat: no-repeat;
  background-size: 68%;
  background-position: top right;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part {
    height: 700px;
    background-image: none;
    background-color: #f7f7f7;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part {
    height: 750px;
    background-image: none;
    background-color: #f7f7f7;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part {
    height: 750px;
    background-image: none;
    background-color: #f7f7f7;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part {
    height: 650px;
  }
}

/* line 30, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text {
  display: table;
  width: 100%;
  height: 880px;
}

/* line 34, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text .banner_text_iner {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 576px) {
  /* line 34, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .banner_text_iner {
    vertical-align: bottom;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 34, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .banner_text_iner {
    vertical-align: bottom;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 34, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .banner_text_iner {
    vertical-align: bottom;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 34, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .banner_text_iner {
    vertical-align: bottom;
  }
}

@media (max-width: 576px) {
  /* line 30, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text {
    text-align: center;
    padding-top: 0px;
    height: 700px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 30, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text {
    text-align: center;
    padding-top: 0px;
    height: 750px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 30, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text {
    text-align: center;
    padding-top: 0px;
    height: 750px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 30, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text {
    height: 650px;
  }
}

/* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text h5 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #556172;
  margin-bottom: 14px;
}

/* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text h1 {
  font-size: 55px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 27px;
  line-height: 1.18;
}

@media (max-width: 576px) {
  /* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text h1 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text h1 {
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text h1 {
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text h1 {
    font-size: 40px;
    margin-bottom: 15px;
    line-height: 1.4;
  }
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text h1 span {
  color: #ff663b;
}

/* line 106, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text p {
  font-size: 15px;
  line-height: 1.8;
  font-family: "Roboto", sans-serif;
  color: #777777;
}

/* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text .btn_1 {
  box-shadow: 0px 12px 20px 0px rgba(255, 126, 95, 0.15);
  margin-top: 50px;
  padding: 13.5px 45px;
}

@media (max-width: 576px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_1 {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_1 {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_1 {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_1 {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 10px 25px;
  }
}

/* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part .banner_text .btn_2 {
  margin-top: 50px;
  padding: 13px 46px;
  margin-left: 10px;
}

@media (max-width: 576px) {
  /* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_2 {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_2 {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_2 {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part .banner_text .btn_2 {
    margin-top: 20px;
    margin-bottom: 70px;
    padding: 10px 25px;
  }
}

/* line 164, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
.banner_part:after {
  position: absolute;
  top: 163px;
  width: 41%;
  height: 69%;
  content: "";
  background-image: url(/images/course-detail/banner_img.png);
  background-size: 100% 100%;
  right: 9%;
}

@media (max-width: 576px) {
  /* line 164, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part:after {
    position: absolute;
    top: 150px;
    max-width: 100%;
    max-height: 100%;
    content: "";
    background-image: url(/images/course-detail/banner_img.png);
    background-size: contain;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 164, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part:after {
    position: absolute;
    top: 110px;
    max-width: 100%;
    max-height: 100%;
    content: "";
    background-image: url(/images/course-detail/banner_img.png);
    background-size: contain;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 164, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part:after {
    position: absolute;
    top: 110px;
    max-width: 100%;
    max-height: 100%;
    content: "";
    background-image: url(/images/course-detail/banner_img.png);
    background-size: contain;
    right: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 164, E:/172 Etrain Education/172_Etrain_Education_html/sass/_banner.scss */
  .banner_part:after {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    content: "";
    background-image: url(/images/course-detail/banner_img.png);
    background-size: contain;
    right: 5%;
    bottom: 0;
    top: auto;
    background-repeat: no-repeat;
  }
}

/**************** hero part css end ****************/
/**************** about css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
.about_part {
  position: relative;
  z-index: 1;
  padding: 70px 0px 140px;
  background-image: url(/images/course-detail/about_overlay.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 33% 73%;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part {
    padding: 70px 0px 70px;
    background-position: top;
    background-size: 100% 43%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part {
    padding: 70px 0px 70px;
    background-position: top left;
    background-size: 90% 56%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part {
    padding: 70px 0px 70px;
    background-position: left;
    background-size: 48% 70%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part {
    padding: 0px 0px 80px;
  }
}

@media (max-width: 576px) {
  /* line 29, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 29, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 29, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text {
    margin-top: 50px;
  }
}

/* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
.about_part .about_text h4 {
  margin-bottom: 32px;
  color: #ff7e5f;
  font-size: 22px;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

@media (max-width: 576px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h4 {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h4 {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h4 {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h4 {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

/* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
.about_part .about_text h2 {
  line-height: 1.25;
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  /* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h2 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h2 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

/* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_about.scss */
.about_part .about_text p {
  margin-top: 13px;
}

/**************** service_part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part {
  padding-top: 65px;
}

/* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text h2 {
  font-size: 42px;
  line-height: 1.222;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text h2 {
    margin-top: 0px;
    font-size: 35px;
  }
}

/* line 32, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text p {
  line-height: 1.8;
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_text .btn_1 {
  margin-top: 45px;
}

@media (max-width: 576px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_text .btn_1 {
    margin-top: 25px;
  }
}

/* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part {
  padding: 50px 20px 35px;
  border: 1px solid #f0f4f6;
  text-align: center;
  transition: 0.6s;
}

@media (max-width: 576px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 25px 10px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 30px 15px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 30px 25px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 57, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    margin-top: 25px;
  }
}

/* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part span {
  margin-bottom: 30px;
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  background-color: #f0f4f6;
  display: inline-block;
  line-height: 80px;
  transition: 0.6s;
}

@media (max-width: 576px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part span {
    margin-bottom: 35px;
  }
}

/* line 110, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part span i {
  color: #0c2e60;
  font-size: 24px;
  transition: 0.6s;
}

/* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 116, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature_part p {
  color: #7f7f7f;
  line-height: 1.8;
  font-size: 15px;
}

/* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover .single_feature_part {
  border: 1px solid #ff663b;
}

/* line 148, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover span {
  background-size: 200% auto;
}

/* line 152, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.feature_part .single_feature:hover span i {
  color: #fff;
}

/* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
.single_feature_padding {
  padding-top: 140px;
}

@media (max-width: 576px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

/************Team css start***************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part {
  position: relative;
  z-index: 99;
  padding: 189px 0px 210px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part {
    padding: 70px 0px;
  }
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img {
  background-image: url("/images/course-detail/learning_img_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 70% 100%;
  background-position: left top;
  position: absolute;
  left: -134px;
  bottom: -69px;
}

@media (max-width: 576px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img {
    bottom: -43px;
    left: -80px;
  }
}

/* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_img img {
  padding: 0px 53px 64px 50px;
}

@media (max-width: 576px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_img img {
    padding: 0;
  }
}

@media (max-width: 576px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 64, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text {
    padding-left: 0;
  }
}

/* line 81, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5 {
  font-family: "Roboto", sans-serif;
  color: #556172;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-left: 75px;
  text-transform: uppercase;
}

/* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h5:after {
  position: absolute;
  left: 0;
  top: 10px;
  height: 2px;
  width: 60px;
  content: "";
  background-color: #ff663b;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 38px;
  position: relative;
  margin-top: 19px;
}

@media (max-width: 576px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text h2 {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 40px;
  }
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text p {
  line-height: 1.929;
  margin-bottom: 7px;
}

@media (max-width: 576px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text p {
    margin-bottom: 20px;
  }
}

/* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 156, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul {
    margin-bottom: 20px;
  }
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 33px;
  padding-top: 12px;
  color: #888888;
}

@media (max-width: 576px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
    padding-top: 5px;
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li {
    padding-left: 41px;
  }
}

/* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text ul li span {
  margin-right: 17px;
  font-size: 16px;
  position: absolute;
  left: 15px;
  padding-top: 2px;
}

@media (max-width: 576px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 204, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .learning_part .learning_member_text ul li span {
    padding-bottom: 15px;
    position: absolute;
    left: 15px;
    padding-top: 6px;
  }
}

/* line 241, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.learning_part .learning_member_text .btn_1 {
  margin-top: 6px;
}

/* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature {
  padding: 169px 0px 171px;
}

@media (max-width: 576px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 0px 0px 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature {
    padding: 70px 0px;
  }
}

/* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img {
  background-image: url("/images/course-detail/advance_feature_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 84% 100%;
  background-position: right top;
  left: 0;
  bottom: -31px;
  right: -82px;
  top: -30px;
}

@media (max-width: 576px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    top: 0px;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: auto;
    top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img {
    position: inherit;
    left: 0;
    bottom: 0;
    margin-bottom: 20px;
  }
}

/* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_img img {
  padding: 0px 117px 0px 0px;
}

@media (max-width: 576px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 295, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_img img {
    padding: 0px;
  }
}

/* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner span {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  background-color: #fdeae5;
  display: inline-block;
  text-align: center;
  font-size: 22px;
  margin-top: 55px;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 313, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner span {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

/* line 340, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner .ti-stamp {
  background-color: #fff0e0;
}

/* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
.advance_feature .learning_member_text_iner h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 17px;
}

@media (max-width: 576px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 343, E:/172 Etrain Education/172_Etrain_Education_html/sass/_learning.scss */
  .advance_feature .learning_member_text_iner h4 {
    margin-bottom: 10px;
  }
}

/**************** service_part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter {
  padding: 73px 0px 73px;
}

@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 50px 0px 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter {
    padding: 73px 0px 73px;
  }
}

/* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter {
  text-align: center;
}

@media (max-width: 576px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter {
    margin: 20px 0px;
  }
}

/* line 41, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter img {
  width: 78px;
  height: 58px;
  display: block;
  margin-bottom: 8px;
  margin-left: -10px;
}

/* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter span {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  font-style: "Poppins", sans-serif;
}

@media (max-width: 576px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter span {
    line-height: 67px;
    font-size: 40px;
  }
}

/* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4 {
  color: #fff;
  font-size: 24px;
  font-size: 600;
  margin-top: 28px;
  text-transform: capitalize;
  position: relative;
}

/* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
.member_counter .single_member_counter h4:after {
  position: absolute;
  left: 0;
  top: -20px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 1px;
  background-color: #fff;
  content: "";
}

@media (max-width: 576px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 84, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4:after {
    top: -10px;
  }
}

@media (max-width: 576px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_member_counter.scss */
  .member_counter .single_member_counter h4 {
    margin-top: 10px;
  }
}

/********** special_cource_css************/
@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource {
    padding: 70px 0px 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource {
    padding: 70px 0px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource {
    padding: 70px 0px 50px;
  }
}

@media (max-width: 576px) {
  /* line 17, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource {
    margin-bottom: 20px;
  }
  /* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 17, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource {
    margin-bottom: 20px;
  }
  /* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 17, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource {
    margin-bottom: 20px;
  }
}

/* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text {
  padding: 35px 35px 40px;
  border: 1px solid #edeff2;
  border-top: 0px;
}

@media (max-width: 576px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text {
    padding: 20px 15px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text {
    padding: 20px 15px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text {
    padding: 20px 15px 15px;
  }
}

/* line 61, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text h4 {
  float: right;
  color: #ff663b;
  font-weight: 600;
}

/* line 67, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 10px;
  transition: 0.5s;
}

/* line 73, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text h3:hover {
  color: #ff663b;
}

@media (max-width: 576px) {
  /* line 67, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text h3 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 67, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text h3 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

/* line 92, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info {
  display: flex;
  justify-content: space-between;
  padding-top: 23px;
  margin-top: 23px;
  border-top: 1px solid #edeff2;
}

/* line 99, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_img {
  position: relative;
  padding-left: 60px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 99, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text .author_info .author_img {
    padding-left: 53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 99, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text .author_info .author_img {
    padding-left: 53px;
  }
}

/* line 109, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_img img {
  position: absolute;
  left: 0;
  top: 0;
}

/* line 115, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_img p {
  color: #556172;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 115, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text .author_info .author_img p {
    font-size: 12px;
  }
}

/* line 130, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_img h5 a {
  color: #0c2e60;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 130, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text .author_info .author_img h5 a {
    font-size: 14px;
  }
}

/* line 141, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_rating {
  float: right;
}

/* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_rating .rating a {
  margin-left: 5px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 145, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
  .special_cource .single_special_cource .special_cource_text .author_info .author_rating .rating a {
    margin-left: 0px;
  }
}

/* line 157, E:/172 Etrain Education/172_Etrain_Education_html/sass/_special_cource.scss */
.special_cource .single_special_cource .special_cource_text .author_info .author_rating p {
  float: right;
}

/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_area .title_top {
  margin-top: 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 13px;
}

/* line 8, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_area .title {
  font-size: 22px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 13px;
  border-top: 1px solid #edeff2;
  padding-top: 30px;
}

/* line 20, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_area .btn_2 {
  padding: 5px 19px;
  font-size: 14px;
  text-transform: capitalize !important;
  border: 1px solid #edeff2;
}

/* line 28, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.comments-area .thumb img {
  width: 100px !important;
  border-radius: 0;
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.desc h5 a {
  color: #0c2e60;
}

/* line 40, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.desc p {
  font-size: 12px !important;
}

/* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .content {
  color: #888888;
  line-height: 1.929;
}

/* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .course_list {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  /* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .course_details_left .course_list {
    padding-left: 0;
  }
}

/* line 56, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .course_list li {
  list-style: none;
  margin-bottom: 20px;
}

/* line 59, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .course_list li .primary-btn {
  background: #f9f9f9;
  box-shadow: none;
  font-size: 12px;
  border-radius: 0px;
}

/* line 65, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .course_list li .primary-btn:hover {
  color: #ffffff;
}

@media (max-width: 575px) {
  /* line 59, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .course_details_left .course_list li .primary-btn {
    min-width: 100px;
    font-size: 10px;
    padding: 0 10px;
  }
}

/* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.course_details_left .course_list li:last-child {
  margin-bottom: 0;
}

/* line 82, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.review-top h6 {
  color: #ee3f0e;
  font-size: 15px;
  margin-bottom: 21px;
  margin-top: 7px;
}

/* line 91, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.feedeback h6 {
  margin-bottom: 16px;
}

/* line 94, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.feedeback .btn_1 {
  padding: 5px 19px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  /* line 100, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .right-contents {
    margin-top: 50px;
  }
}

/* line 104, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top {
  padding: 40px 30px;
  border: 1px solid #edeff2;
}

/* line 107, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top .btn_1 {
  text-align: center;
  margin-top: 20px;
}

/* line 111, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top span {
  color: #888888;
}

/* line 114, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top .color {
  color: #0c2e60;
  font-size: 15px;
}

/* line 119, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top ul li {
  list-style: none;
  padding: 10px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid #edeff2;
}

/* line 124, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top ul li:last-child {
  border-bottom: 0px solid #edeff2;
}

/* line 127, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top ul li a {
  text-align: left;
}

/* line 130, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top ul li a p {
  margin-bottom: 0px;
}

/* line 134, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .sidebar_top ul li .or {
  font-weight: 500;
}

/* line 141, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .enroll {
  margin-top: 10px;
  width: 100%;
}

/* line 146, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .reviews span,
.right-contents .reviews .star {
  width: 31%;
  margin-bottom: 10px;
  color: #888888;
  font-size: 15px;
}

/* line 154, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .avg-review {
  background: #04091e;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 0px;
}

/* line 161, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .avg-review span {
  font-size: 18px;
}

@media (max-width: 991px) {
  /* line 154, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .right-contents .avg-review {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  /* line 170, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .right-contents .single-reviews .thumb {
    margin-right: 10px;
  }
}

/* line 175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .single-reviews h5 {
  display: inline-flex;
}

@media (max-width: 1024px) {
  /* line 175, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .right-contents .single-reviews h5 {
    display: block;
  }
}

/* line 180, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .single-reviews h5 .star {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  /* line 180, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
  .right-contents .single-reviews h5 .star {
    margin: 10px 0;
  }
}

/* line 188, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .feedeback {
  margin-top: 30px;
}

/* line 190, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .feedeback textarea {
  resize: none;
  height: 130px;
  border: 1px solid #edeff2;
  border-radius: 0px;
}

/* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .feedeback textarea:focus {
  box-shadow: none;
}

/* line 205, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .comments-area {
  padding: 0;
  border: 0;
  background: transparent;
}

/* line 209, E:/172 Etrain Education/172_Etrain_Education_html/sass/_cource_details.scss */
.right-contents .comments-area .star {
  margin-left: 20px;
}

/******************* testimonial part css88********************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part {
  overflow: hidden;
}

/* line 4, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .section_tittle {
  margin-bottom: 80px;
}

@media (max-width: 576px) {
  /* line 4, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .section_tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 4, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .section_tittle {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 4, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .section_tittle {
    margin-bottom: 50px;
  }
}

/* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .textimonial_iner {
  margin-left: 13%;
  overflow: hidden;
}

@media (max-width: 576px) {
  /* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .textimonial_iner {
    margin-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .textimonial_iner {
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .textimonial_iner {
    margin-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .textimonial_iner {
    margin-left: 0;
  }
}

/* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .testimonial_slider {
  margin: 30px;
}

@media (max-width: 576px) {
  /* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider {
    margin: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider {
    margin: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider {
    margin: 0px;
  }
}

/* line 50, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .testimonial_slider_text {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(12, 46, 96, 0.1);
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  /* line 50, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text {
    padding: 5px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 50, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text {
    padding: 5px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 50, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text {
    padding: 5px 20px;
  }
}

/* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .testimonial_slider_text:after {
  position: absolute;
  right: 16%;
  top: 59px;
  width: 148px;
  height: 124px;
  background-image: url(/images/course-detail/quote.png);
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

@media (max-width: 576px) {
  /* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text:after {
    width: 53px;
    height: 45px;
    right: 25%;
    top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text:after {
    width: 53px;
    height: 45px;
    right: 25%;
    top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider_text:after {
    width: 53px;
    height: 45px;
    right: 25%;
    top: 30px;
  }
}

/* line 103, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .owl-dots {
  text-align: center;
  padding-top: 67px;
  margin-left: -26%;
  line-height: 0px;
}

@media (max-width: 576px) {
  /* line 103, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .owl-dots {
    margin-left: 0;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 103, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .owl-dots {
    margin-left: 0;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 103, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .owl-dots {
    margin-left: 0;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 103, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .owl-dots {
    margin-left: 0;
    padding-top: 20px;
  }
}

/* line 124, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #d7d7d7;
  margin: 0 10px;
}

/* line 132, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .owl-dots button.owl-dot.active {
  background-color: #ff663b;
  width: 17px;
  border-radius: 50px;
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .owl-dots button.owl-dot:focus {
  outline: none;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider {
    margin: 0px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
  .testimonial_part .testimonial_slider {
    margin: 0px 20px;
  }
}

/* line 150, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part .testimonial_slider p {
  font-size: 15px;
  font-style: italic;
}

/* line 157, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part h4 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
}

/* line 163, E:/172 Etrain Education/172_Etrain_Education_html/sass/_testimonial_part.scss */
.testimonial_part h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #888888;
}

/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area {
  background-color: #f7f7f7;
  padding: 100px 0px 20px;
}

@media (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area {
    padding: 70px 0px 30px;
  }
}

/* line 8, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .form-control {
  background-color: transparent;
}

/* line 11, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .form-group {
  margin-top: 25px;
}

@media (max-width: 991px) {
  /* line 14, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget {
    margin-bottom: 30px;
  }
}

/* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget p {
  font-size: 14px;
  line-height: 1.8;
}

/* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget h4 {
  margin-bottom: 23px;
  font-weight: 700;
  font-size: 24px;
}

@media (max-width: 1024px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget h4 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget h4 {
    margin-bottom: 15px;
  }
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li {
  margin-bottom: 10px;
}

/* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li a {
  color: #555555;
  transition: 0.5s;
  font-size: 15px;
}

/* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li a:hover {
  color: #ff663b;
}

/* line 46, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li:last-child {
  margin-bottom: 0px;
}

/* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .form-wrap {
  margin-top: 25px;
}

/* line 54, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget input {
  height: 40px;
  border: none;
  width: 67% !important;
  font-weight: 400;
  padding-left: 20px;
  border-radius: 0;
  font-size: 13px;
  color: #999999;
  border: 0px solid transparent;
  font-family: "Roboto", sans-serif;
}

/* line 65, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget input:focus {
  outline: none;
  box-shadow: none;
}

/* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .click-btn {
  background-color: #ff663b;
  color: #fff;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 20px;
  border: 0;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  position: relative;
  left: 0;
}

/* line 83, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .click-btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 375px) {
  /* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget .click-btn {
    margin-top: 10px;
  }
}

@media (min-width: 400px) {
  /* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget .click-btn {
    left: -50px;
  }
}

/* line 98, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_1 img {
  margin-bottom: 35px;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_1 p {
  margin-bottom: 15px;
}

/* line 106, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_2 .social_icon {
  margin-top: 27px;
}

/* line 108, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_2 .social_icon a {
  color: #cccccc;
  font-size: 14px;
  margin-right: 20px;
}

/* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img {
  width: 30%;
  float: left;
  margin: 1%;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 10%;
  }
}

/* line 135, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s;
}

/* line 146, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img i {
  text-align: center;
  position: absolute;
  top: 41%;
  margin: 0 auto;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 2;
  opacity: 0;
}

/* line 158, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:hover:after {
  opacity: 0.5;
}

/* line 161, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:hover i {
  opacity: 1;
}

/* line 168, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info {
  position: relative;
  margin-bottom: 20px;
}

/* line 171, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info:last-child {
  margin-bottom: 0px;
}

/* line 174, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info p {
  margin-bottom: 10px;
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info p span {
  color: #0c2e60;
  font-size: 16px;
}

/* line 182, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .btn {
  background-color: #ff663b;
  color: #fff;
  width: 40px;
  border-radius: 0px;
  height: 40px;
  padding: 0;
  border-radius: 2px !important;
}

/* line 191, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area span.ti-heart {
  font-size: 12px;
  margin: 0px 2px;
}

/* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text {
  padding-top: 26px;
  margin-top: 112px;
  border-top: 1px solid #dedede;
}

/* line 199, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text p {
  font-size: 15px;
}

@media (max-width: 576px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    margin-top: 20px;
    text-align: center;
  }
  /* line 206, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    text-align: center;
    margin-top: 20px;
  }
  /* line 213, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    margin-top: 42px;
    text-align: center;
  }
}

/* line 224, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text a {
  color: #ff663b;
}

/* line 228, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .input-group {
  border: 1px solid #dedede;
  padding: 5px;
}

/* line 231, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .input-group ::-webkit-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group :-ms-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group ::-ms-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group ::placeholder {
  font-size: 13px;
  color: #999;
}

/* line 239, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  overflow: hidden;
}

/* line 244, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .btn_1 {
  margin-top: 0px;
}

/* line 75, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

/* line 80, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

/* line 85, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
b,
sup,
sub,
u,
del {
  color: #ff663b;
}

/* line 93, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h1 {
  font-size: 36px;
}

/* line 97, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h2 {
  font-size: 30px;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h3 {
  font-size: 24px;
}

/* line 105, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h4 {
  font-size: 18px;
}

/* line 109, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h5 {
  font-size: 16px;
}

/* line 113, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h6 {
  font-size: 14px;
}

/* line 117, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}

/* line 127, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

/* line 137, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.button-area {
  background: #fff;
}

/* line 138, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

/* line 146, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

/* line 149, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

/* line 155, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

/* line 166, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn:focus {
  outline: none;
}

/* line 169, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

/* line 173, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.large {
  line-height: 45px;
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.medium {
  line-height: 30px;
}

/* line 179, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.small {
  line-height: 25px;
}

/* line 182, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.radius {
  border-radius: 3px;
}

/* line 185, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.circle {
  border-radius: 20px;
}

/* line 188, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.arrow {
  display: inline-flex;
  align-items: center;
}

/* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.arrow span {
  margin-left: 10px;
}

/* line 199, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 203, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 208, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 211, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 217, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.primary {
  color: #fff;
  background: #ff663b;
  border: 1px solid transparent;
}

/* line 221, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.primary:hover {
  color: #ff663b;
  border: 1px solid #ff663b;
  background: #fff;
}

/* line 227, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.primary-border {
  color: #ff663b;
  border: 1px solid #ff663b;
  background: #fff;
}

/* line 231, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.primary-border:hover {
  color: #fff;
  background: #ff663b;
  border: 1px solid transparent;
}

/* line 237, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 241, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 247, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 251, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 257, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 261, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 267, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 271, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 277, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 281, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 287, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 291, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 297, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 301, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 307, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 311, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 317, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

/* line 322, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 328, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

/* line 333, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 339, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

/* line 347, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #ff663b;
}

/* line 353, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table-wrap {
  overflow-x: scroll;
}

/* line 357, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

/* line 361, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

/* line 365, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .country {
  width: 28.07%;
}

/* line 368, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .visit {
  width: 19.74%;
}

/* line 371, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

/* line 375, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-head {
  display: flex;
}

/* line 377, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

/* line 387, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

/* line 391, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

/* line 399, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .country img {
  margin-right: 15px;
}

/* line 404, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

/* line 408, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

/* line 411, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

/* line 414, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

/* line 417, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

/* line 420, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

/* line 423, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

/* line 426, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

/* line 429, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

/* line 432, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

/* line 441, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

/* line 449, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.list-style {
  width: 14px;
  height: 14px;
}

/* line 455, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

/* line 459, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #ff663b;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

/* line 473, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list {
  margin-left: 30px;
}

/* line 475, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #ff663b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 480, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 488, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #ff663b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 494, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 502, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #ff663b;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 508, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 515, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 523, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input:focus {
  outline: none;
}

/* line 528, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.input-group-icon {
  position: relative;
}

/* line 530, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

/* line 535, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.input-group-icon .icon i {
  color: #797979;
}

/* line 540, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.input-group-icon .single-input {
  padding-left: 45px;
}

/* line 545, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

/* line 555, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-textarea:focus {
  outline: none;
}

/* line 560, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 568, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-primary:focus {
  outline: none;
  border: 1px solid #ff663b;
}

/* line 574, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 582, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

/* line 588, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 596, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

/* line 602, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 609, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 619, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff663b;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 632, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-switch input:checked + label {
  left: 19px;
}

/* line 639, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 646, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 655, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 663, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  transition: all 0.2s;
}

/* line 677, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 693, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input:checked + label:after {
  left: 19px;
}

/* line 696, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-switch input:checked + label:before {
  background: #ff663b;
}

/* line 704, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 711, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 720, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 728, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  transition: all 0.2s;
  cursor: pointer;
}

/* line 742, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 758, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input:checked + label:after {
  left: 19px;
}

/* line 761, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

/* line 769, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 776, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 785, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 798, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-checkbox input:checked + label {
  background: url(/images/course-detail/elements/primary-check.png) no-repeat center center/cover;
  border: none;
}

/* line 806, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 813, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 822, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 835, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-checkbox input:checked + label {
  background: url(/images/course-detail/elements/success-check.png) no-repeat center center/cover;
  border: none;
}

/* line 843, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 850, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 859, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 871, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 876, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-checkbox input:checked + label {
  background: url(/images/course-detail/elements/disabled-check.png) no-repeat center center/cover;
  border: none;
}

/* line 884, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 891, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 900, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 913, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.primary-radio input:checked + label {
  background: url(/images/course-detail/elements/primary-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 921, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 928, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 937, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 950, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.confirm-radio input:checked + label {
  background: url(/images/course-detail/elements/success-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 958, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 965, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 974, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 986, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 991, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.disabled-radio input:checked + label {
  background: url(/images/course-detail/elements/disabled-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 999, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select {
  height: 40px;
}

/* line 1001, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

/* line 1008, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1015, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select .list .option {
  font-weight: 300;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

/* line 1022, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select .list .option.selected {
  color: #ff663b;
  background: transparent;
}

/* line 1026, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select .list .option:hover {
  color: #ff663b;
  background: transparent;
}

/* line 1033, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1037, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.default-select .nice-select::after {
  right: 20px;
}

/* line 1042, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select {
  height: 40px;
  width: 100%;
}

/* line 1045, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

/* line 1053, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1060, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select .list .option {
  font-weight: 300;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

/* line 1067, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select .list .option.selected {
  color: #ff663b;
  background: transparent;
}

/* line 1071, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select .list .option:hover {
  color: #ff663b;
  background: transparent;
}

/* line 1078, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1082, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.form-select .nice-select::after {
  right: 20px;
}

/* line 1086, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 1089, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

/* line 1093, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 1096, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 1099, E:/172 Etrain Education/172_Etrain_Education_html/sass/_elements.scss */
.switch-wrap {
  margin-bottom: 10px;
}

/**************** blog part css start ****************/
@media (max-width: 576px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part {
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part {
    padding-bottom: 50px;
  }
}

/* line 15, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .card {
  border: 0px solid transparent;
}

/* line 18, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
  font-style: inherit !important;
}

@media (max-width: 576px) {
  /* line 23, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 23, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 23, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog {
    margin-bottom: 20px;
  }
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card-img-top {
  border-radius: 0px;
}

/* line 39, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card {
  border-radius: 0px;
  background-color: transparent;
  position: relative;
}

/* line 44, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .card-body {
  padding: 35px 30px 23px;
  background-color: #fff;
  transition: 0.5s;
  border: 1px solid #edeff2;
}

/* line 49, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .card-body:hover {
  box-shadow: 0px 10px 30px 0px rgba(12, 46, 96, 0.1);
  border: 1px solid transparent;
}

@media (max-width: 576px) {
  /* line 44, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 44, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 44, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card .card-body {
    padding: 20px;
  }
}

/* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .card-body .btn_4 {
  margin-bottom: 20px;
}

/* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .card-body a {
  color: #fff;
  text-transform: capitalize;
  transition: 0.8s;
}

/* line 76, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .dot {
  position: relative;
  padding-left: 20px;
}

/* line 79, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card .dot:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 5px;
  left: 0;
  background-color: #ff663b;
  border-radius: 50%;
}

/* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card span {
  color: #8a8a8a;
  margin-bottom: 10px;
  display: inline-block;
  margin-top: 10px;
}

@media (max-width: 576px) {
  /* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 90, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card span {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

/* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card h5 {
  font-weight: 600;
  line-height: 1.5;
  font-size: 19px;
  transition: 0.8s;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 5px;
    font-size: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 112, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

/* line 133, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card h5:hover {
  transition: 0.8s;
  color: #ff663b;
}

/* line 139, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card ul {
  border-top: 1px solid #edeff2;
  padding-top: 20px;
  margin-top: 24px;
}

/* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card ul li {
  display: inline-block;
  color: #8a8a8a;
  margin-right: 39px;
}

@media (max-width: 576px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 143, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
  .blog_part .single-home-blog .card ul li {
    margin-right: 10px;
  }
}

/* line 159, E:/172 Etrain Education/172_Etrain_Education_html/sass/_blog_part.scss */
.blog_part .single-home-blog .card ul li span {
  margin-right: 10px;
}

/**************** copyright part css start ****************/
/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part {
  background-color: #303030;
  padding: 26px 0px;
}

/* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part p {
  color: #8a8a8a;
  font-family: 300;
}

/* line 9, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part a {
  color: #ff663b;
}

@media (max-width: 576px) {
  /* line 12, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
  .copyright_part .footer-social {
    margin-top: 20px;
  }
}

/* line 25, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part .footer-social a {
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  border: 1px solid #ff7e5f;
  text-align: center;
  margin-left: 10px;
  color: #fff;
}

/* line 35, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part .footer-social a:hover {
  background-color: #ff7e5f !important;
  color: #fff !important;
}

/* line 40, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part .footer-social a i:hover {
  color: #fff;
}

@media (max-width: 576px) {
  /* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
  .copyright_part .footer-text {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 52, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
  .copyright_part .footer-text {
    text-align: center;
    margin-bottom: 25px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 58, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
  .copyright_part .footer-text {
    text-align: center;
    margin-bottom: 25px !important;
  }
}

/* line 65, E:/172 Etrain Education/172_Etrain_Education_html/sass/_copyright_part.scss */
.copyright_part span.ti-heart {
  font-size: 12px;
  margin: 0px 2px;
}

/*=================== contact banner start ====================*/
/* line 3, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info {
  margin-bottom: 25px;
}

/* line 6, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info__icon {
  margin-right: 20px;
}

/* line 9, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info__icon i, .contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

/* line 17, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

/* line 23, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info .media-body h3 a:hover {
  color: #ff663b;
}

/* line 29, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
/* line 39, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* line 47, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact label {
  font-size: 14px;
}

/* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact .form-group {
  margin-bottom: 30px;
}

/* line 53, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact .form-group .btn_1 {
  margin-bottom: -30px;
}

/* line 58, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 66, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 71, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact .form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.form-contact .form-control:-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.form-contact .form-control::-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
}
.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 77, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.form-contact textarea {
  border-radius: 12px;
  height: 100% !important;
}

@media (max-width: 576px) {
  /* line 89, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 97, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 105, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 113, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
  .contact-section .btn_1 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
/* line 126, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

/* line 135, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 141, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

/* line 150, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

/* line 155, E:/172 Etrain Education/172_Etrain_Education_html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/* line 1, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb_bg {
  background-image: url("/images/course-detail/breadcrumb.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 5, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb {
  position: relative;
  z-index: 1;
  text-align: center;
}

/* line 10, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  content: "";
  opacity: 0.8;
  z-index: -1;
}

/* line 22, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner {
  height: 450px;
  width: 100%;
  display: table;
}

/* line 27, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item {
  display: table-cell;
  vertical-align: middle;
}

/* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  /* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 31, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 40px;
  }
}

/* line 53, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item p {
  font-size: 15px;
  color: #fff;
}

/* line 58, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item span {
  margin: 0px 5px;
  font-size: 12px;
}

/* line 68, E:/172 Etrain Education/172_Etrain_Education_html/sass/_breadcrumb.scss */
.breadcrumb {
  margin-bottom: 0px !important;
}

/* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area {
  background-color: #f7f7f7;
  padding: 100px 0px 20px;
}

@media (max-width: 991px) {
  /* line 2, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area {
    padding: 70px 0px 30px;
  }
}

/* line 8, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .form-control {
  background-color: transparent;
}

/* line 11, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .form-group {
  margin-top: 25px;
}

@media (max-width: 991px) {
  /* line 14, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget {
    margin-bottom: 30px;
  }
}

/* line 19, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget p {
  font-size: 14px;
  line-height: 1.8;
}

/* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget h4 {
  margin-bottom: 23px;
  font-weight: 700;
  font-size: 24px;
}

@media (max-width: 1024px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget h4 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  /* line 24, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget h4 {
    margin-bottom: 15px;
  }
}

/* line 36, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li {
  margin-bottom: 10px;
}

/* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li a {
  color: #555555;
  transition: 0.5s;
  font-size: 15px;
}

/* line 42, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li a:hover {
  color: #ff663b;
}

/* line 46, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget ul li:last-child {
  margin-bottom: 0px;
}

/* line 51, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .form-wrap {
  margin-top: 25px;
}

/* line 54, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget input {
  height: 40px;
  border: none;
  width: 67% !important;
  font-weight: 400;
  padding-left: 20px;
  border-radius: 0;
  font-size: 13px;
  color: #999999;
  border: 0px solid transparent;
  font-family: "Roboto", sans-serif;
}

/* line 65, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget input:focus {
  outline: none;
  box-shadow: none;
}

/* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .click-btn {
  background-color: #ff663b;
  color: #fff;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 20px;
  border: 0;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  position: relative;
  left: 0;
}

/* line 83, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .single-footer-widget .click-btn:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 375px) {
  /* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget .click-btn {
    margin-top: 10px;
  }
}

@media (min-width: 400px) {
  /* line 70, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .single-footer-widget .click-btn {
    left: -50px;
  }
}

/* line 98, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_1 img {
  margin-bottom: 35px;
}

/* line 101, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_1 p {
  margin-bottom: 15px;
}

/* line 106, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_2 .social_icon {
  margin-top: 27px;
}

/* line 108, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_2 .social_icon a {
  color: #cccccc;
  font-size: 14px;
  margin-right: 20px;
}

/* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img {
  width: 30%;
  float: left;
  margin: 1%;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 15%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 10%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 118, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .footer_3 .footer_img .single_footer_img {
    width: 10%;
  }
}

/* line 135, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s;
}

/* line 146, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img i {
  text-align: center;
  position: absolute;
  top: 41%;
  margin: 0 auto;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 2;
  opacity: 0;
}

/* line 158, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:hover:after {
  opacity: 0.5;
}

/* line 161, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .footer_3 .footer_img .single_footer_img:hover i {
  opacity: 1;
}

/* line 168, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info {
  position: relative;
  margin-bottom: 20px;
}

/* line 171, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info:last-child {
  margin-bottom: 0px;
}

/* line 174, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info p {
  margin-bottom: 10px;
}

/* line 176, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .contact_info p span {
  color: #0c2e60;
  font-size: 16px;
}

/* line 182, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .btn {
  background-color: #ff663b;
  color: #fff;
  width: 40px;
  border-radius: 0px;
  height: 40px;
  padding: 0;
  border-radius: 2px !important;
}

/* line 191, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area span.ti-heart {
  font-size: 12px;
  margin: 0px 2px;
}

/* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text {
  padding-top: 26px;
  margin-top: 112px;
  border-top: 1px solid #dedede;
}

/* line 199, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text p {
  font-size: 15px;
}

@media (max-width: 576px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    margin-top: 20px;
    text-align: center;
  }
  /* line 206, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    text-align: center;
    margin-top: 20px;
  }
  /* line 213, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 195, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
  .footer-area .copyright_part_text {
    margin-top: 42px;
    text-align: center;
  }
}

/* line 224, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .copyright_part_text a {
  color: #ff663b;
}

/* line 228, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .input-group {
  border: 1px solid #dedede;
  padding: 5px;
}

/* line 231, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .input-group ::-webkit-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group :-ms-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group ::-ms-input-placeholder {
  font-size: 13px;
  color: #999;
}
.footer-area .input-group ::placeholder {
  font-size: 13px;
  color: #999;
}

/* line 239, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  overflow: hidden;
}

/* line 244, E:/172 Etrain Education/172_Etrain_Education_html/sass/_footer.scss */
.footer-area .btn_1 {
  margin-top: 0px;
}

/*# sourceMappingURL=style.css.map */

.url{
  color:white !important;
}

.url:hover{  
  text-decoration: underline !important;
}
.main-wrapper {
    position: relative;
    top: 42px;
    height: auto;
    margin-top: 64px;
    margin-bottom: 64px;
}

.welcome-wrapper{
    text-align: center;
}

.welcome-wrapper img{
    margin: 0 auto;
}

.wrapper-div{
    width: 50%;
    margin: 0 auto;
}

.form-control:focus{
    box-shadow: none;
}
.align-center, .center {
    text-align: center!important;
}

.middle{
    display: flex;
    align-items: center;
}

.profile-user-info {
    display: table;
    width: 98%;
    width: calc(100% - 24px);
    margin: 0 auto
}

.profile-info-row {
    display: table-row
}

.profile-info-name,
.profile-info-value {
    display: table-cell;
    border-top: 1px dotted #D5E4F1
}

.profile-info-name {
    text-align: left;
    padding: 6px 10px 6px 4px;
    font-weight: 400;
    color: #667E99;
    background-color: transparent;
    width: 110px;
    vertical-align: middle
}

.profile-info-value {
    padding: 6px 4px 6px 6px
}

.profile-info-value>span+span:before {
    display: inline;
    content: ",";
    margin-left: 1px;
    margin-right: 3px;
    color: #666;
    border-bottom: 1px solid #FFF
}

.profile-info-value>span+span.editable-container:before {
    display: none
}

.profile-info-row:first-child .profile-info-name,
.profile-info-row:first-child .profile-info-value {
    border-top: none
}

.profile-user-info-striped {
    border: 1px solid #DCEBF7
}

.profile-user-info-striped .profile-info-name {
    color: #336199;
    background-color: #EDF3F4;
    border-top: 1px solid #F7FBFF
}

.profile-user-info-striped .profile-info-value {
    border-top: 1px dotted #DCEBF7;
    padding-left: 12px
}

.profile-picture {
    border: 1px solid #CCC;
    background-color: #FFF;
    padding: 4px;
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .15)
}

.dd-empty,
.dd-handle,
.dd-placeholder,
.dd2-content {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box
}

.profile-activity {
    padding: 10px 4px;
    border-bottom: 1px dotted #D0D8E0;
    position: relative;
    border-left: 1px dotted #FFF;
    border-right: 1px dotted #FFF
}

.profile-activity:first-child {
    border-top: 1px dotted transparent
}

.profile-activity:first-child:hover {
    border-top-color: #D0D8E0
}

.profile-activity:hover {
    background-color: #F4F9FD;
    border-left: 1px dotted #D0D8E0;
    border-right: 1px dotted #D0D8E0
}

.profile-activity img {
    border: 2px solid #C9D6E5;
    border-radius: 100%;
    max-width: 40px;
    margin-right: 10px;
    margin-left: 0;
    box-shadow: none
}

.profile-activity .thumbicon {
    background-color: #74ABD7;
    display: inline-block;
    border-radius: 100%;
    width: 38px;
    height: 38px;
    color: #FFF;
    font-size: 18px;
    text-align: center;
    line-height: 38px;
    margin-right: 10px;
    margin-left: 0;
    text-shadow: none!important
}

.profile-activity .time {
    display: block;
    margin-top: 4px;
    color: #777
}

.profile-activity a.user {
    font-weight: 700;
    color: #9585BF
}

.profile-activity .tools {
    position: absolute;
    right: 12px;
    bottom: 8px;
    display: none
}

.profile-activity:hover .tools {
    display: block
}

.user-profile .ace-thumbnails li {
    border: 1px solid #CCC;
    padding: 3px;
    margin: 6px
}

.user-profile .ace-thumbnails li .tools {
    left: 3px;
    right: 3px
}

.user-profile .ace-thumbnails li:hover .tools {
    bottom: 3px
}

.user-title-label:hover {
    text-decoration: none
}

.user-title-label+.dropdown-menu {
    margin-left: -12px
}

.profile-contact-links {
    padding: 4px 2px 5px;
    border: 1px solid #E0E2E5;
    background-color: #F8FAFC
}

.btn-link:hover .ace-icon {
    text-decoration: none!important
}

.profile-social-links>a:hover>.ace-icon,
.profile-users .memberdiv .name a:hover .ace-icon,
.profile-users .memberdiv .tools>a:hover {
    text-decoration: none
}

.profile-social-links>a {
    text-decoration: none;
    margin: 0 1px
}

.profile-skills .progress {
    height: 26px;
    margin-bottom: 2px;
    background-color: transparent
}

.profile-skills .progress .progress-bar {
    line-height: 26px;
    font-size: 13px;
    font-weight: 700;
    font-family: "Open Sans";
    padding: 0 8px
}

.profile-users .user {
    display: block;
    position: static;
    text-align: center;
    width: auto
}

.profile-users .user img {
    padding: 2px;
    border-radius: 100%;
    border: 1px solid #AAA;
    max-width: none;
    width: 64px;
    transition: all .1s
}

.profile-users .user img:hover {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .33)
}

.profile-users .memberdiv {
    background-color: #FFF;
    width: 100px;
    box-sizing: border-box;
    border: none;
    text-align: center;
    margin: 0 8px 24px
}

.profile-users .memberdiv .body {
    display: inline-block;
    margin: 8px 0 0
}

.profile-users .memberdiv .popover {
    visibility: hidden;
    min-width: 0;
    max-height: 0;
    max-width: 0;
    margin-left: 0;
    margin-right: 0;
    top: -5%;
    left: auto;
    right: auto;
    opacity: 0;
    display: none;
    position: absolute;
    transition: opacity .2s linear 0s, visibility 0s linear .2s, max-height 0s linear .2s, max-width 0s linear .2s, min-width 0s linear .2s
}

.profile-users .memberdiv .popover.right {
    left: 100%;
    right: auto;
    display: block
}

.profile-users .memberdiv .popover.left {
    left: auto;
    right: 100%;
    display: block
}

.profile-users .memberdiv>:first-child:hover .popover {
    visibility: visible;
    opacity: 1;
    z-index: 1060;
    max-height: 250px;
    max-width: 250px;
    min-width: 150px;
    transition-delay: 0s
}

.profile-users .memberdiv .tools {
    position: static;
    display: block;
    width: 100%;
    margin-top: 2px
}

.profile-users .memberdiv .tools>a {
    margin: 0 2px
}

.user-status {
    display: inline-block;
    width: 11px;
    height: 11px;
    background-color: #FFF;
    border: 3px solid #AAA;
    border-radius: 100%;
    vertical-align: middle;
    margin-right: 1px
}

.user-status.status-online {
    border-color: #8AC16C
}

.user-status.status-busy {
    border-color: #E07F69
}

.user-status.status-idle {
    border-color: #FFB752
}

.tab-content.profile-edit-tab-content {
    border: 1px solid #DDD;
    padding: 8px 32px 32px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, .2);
    background-color: #FFF
}

@media only screen and (max-width:480px) {
    .profile-info-name {
        width: 80px
    }
    .profile-user-info-striped .profile-info-name {
        float: none;
        width: auto;
        text-align: left;
        padding: 6px 4px 6px 10px;
        display: block
    }
    .profile-user-info-striped .profile-info-value {
        margin-left: 10px;
        display: block
    }
    .user-profile .memberdiv {
        width: 50%;
        margin-left: 0;
        margin-right: 0
    }
}



.itemdiv {
    padding-right: 3px;
    min-height: 66px
}

.itemdiv>.user {
    display: inline-block;
    width: 42px;
    position: absolute;
    left: 0
}

.itemdiv>.user>.img,
.itemdiv>.user>img {
    border-radius: 100%;
    border: 2px solid #5293C4;
    max-width: 40px;
    position: relative
}

.itemdiv>.user>.img {
    padding: 2px
}

.itemdiv>.body {
    width: auto;
    margin-left: 50px;
    margin-right: 12px;
    position: relative
}

.itemdiv>.body>.time {
    display: block;
    font-size: 11px;
    font-weight: 700;
    color: #666;
    position: absolute;
    right: 9px;
    top: 0
}

.itemdiv>.body>.time .ace-icon {
    font-size: 14px;
    font-weight: 400
}

.itemdiv>.body>.name {
    display: block;
    color: #999
}

.itemdiv>.body>.name>b {
    color: #777
}

.itemdiv>.body>.text {
    display: block;
    position: relative;
    margin-top: 2px;
    padding-bottom: 19px;
    padding-left: 7px;
    font-size: 13px
}

.itemdiv.dialogdiv:before,
.itemdiv.dialogdiv>.body:before,
.itemdiv>.body>.text:after {
    content: "";
    position: absolute
}

.itemdiv>.body>.text:after {
    display: block;
    height: 1px;
    font-size: 0;
    overflow: hidden;
    left: 16px;
    right: -12px;
    margin-top: 9px;
    border-top: 1px solid #E4ECF3
}

.itemdiv>.body>.text>.ace-icon:first-child {
    color: #DCE3ED;
    margin-right: 4px
}

.itemdiv:last-child>.body>.text {
    border-bottom-width: 0
}

.itemdiv:last-child>.body>.text:after {
    display: none
}

.itemdiv.dialogdiv {
    padding-bottom: 14px
}

.itemdiv.dialogdiv:before {
    display: block;
    top: 0;
    bottom: 0;
    left: 19px;
    width: 3px;
    max-width: 3px;
    background-color: #E1E6ED;
    border: 1px solid #D7DBDD;
    border-width: 0 1px
}

.itemdiv.dialogdiv:last-child {
    padding-bottom: 0
}

.itemdiv.dialogdiv:last-child:before {
    display: none
}

.itemdiv.dialogdiv>.user>img {
    border-color: #C9D6E5
}

.itemdiv.dialogdiv>.body {
    border: 1px solid #DDE4ED;
    padding: 5px 8px 8px;
    border-left-width: 2px;
    margin-right: 1px
}

.itemdiv.dialogdiv>.body:before {
    display: block;
    left: -7px;
    top: 11px;
    width: 8px;
    height: 8px;
    border: 2px solid #DDE4ED;
    border-width: 2px 0 0 2px;
    background-color: #FFF;
    box-sizing: content-box;
    transform: rotate(-45deg)
}

.itemdiv.dialogdiv>.body>.time {
    position: static;
    float: right
}

.itemdiv.dialogdiv>.body>.text {
    padding-left: 0;
    padding-bottom: 0
}

.itemdiv.dialogdiv>.body>.text:after {
    display: none
}

.itemdiv.dialogdiv .tooltip-inner {
    word-break: break-all
}

.itemdiv.memberdiv {
    width: 175px;
    padding: 2px;
    margin: 3px 0;
    float: left;
    border-bottom: 1px solid #E8E8E8
}

@media (min-width:992px) {
    .itemdiv.memberdiv {
        max-width: 50%
    }
}

@media (max-width:991px) {
    .itemdiv.memberdiv {
        min-width: 33.333%
    }
}

.itemdiv.memberdiv>.user>img {
    border-color: #DCE3ED
}

.itemdiv.memberdiv>.body>.time {
    position: static
}

.itemdiv.memberdiv>.body>.name {
    line-height: 18px;
    height: 18px;
    margin-bottom: 0
}

.itemdiv.memberdiv>.body>.name>a {
    display: inline-block;
    max-width: 100px;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all
}

.itemdiv .tools {
    position: absolute;
    right: 5px;
    bottom: 10px;
    display: none
}

.item-list>li>.checkbox,
.item-list>li>label.inline,
.itemdiv:hover .tools {
    display: inline-block
}

.itemdiv .tools .btn {
    border-radius: 36px;
    margin: 1px 0
}

.itemdiv .body .tools {
    bottom: 4px
}

.itemdiv.commentdiv .tools {
    right: 9px
}

.item-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.item-list>li {
    padding: 9px;
    background-color: #FFF;
    margin-top: -1px;
    position: relative
}

.item-list>li.selected {
    color: #8090A0;
    background-color: #F4F9FC
}

.item-list>li.selected .lbl,
.item-list>li.selected label {
    text-decoration: line-through;
    color: #8090A0
}

.item-list>li label {
    font-size: 13px
}

.item-list>li .percentage {
    font-size: 11px;
    font-weight: 700;
    color: #777
}


.ace-thumbnails>li,
.ace-thumbnails>li>:first-child {
    display: block;
    position: relative
}

.ace-thumbnails {
    list-style: none;
    margin: 0;
    padding: 0
}

.ace-thumbnails>li {
    float: left;
    overflow: hidden;
    margin: 2px;
    border: 2px solid #333
}

.ace-thumbnails>li>:first-child:focus {
    outline: 0
}

.ace-thumbnails>li .tags {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    overflow: visible;
    direction: rtl;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    background-color: transparent;
    border-width: 0;
    vertical-align: inherit
}

.ace-thumbnails>li .tags>.label-holder {
    opacity: .92;
    filter: alpha(opacity=92);
    display: table;
    margin: 1px 0 0;
    direction: ltr;
    text-align: left
}

.ace-thumbnails>li>.tools,
.ace-thumbnails>li>:first-child>.text {
    position: absolute;
    text-align: center;
    background-color: rgba(0, 0, 0, .55)
}

.ace-thumbnails>li .tags>.label-holder:hover {
    opacity: 1;
    filter: alpha(opacity=100)
}

.ace-thumbnails>li>.tools {
    top: 0;
    bottom: 0;
    left: -30px;
    width: 24px;
    vertical-align: middle;
    transition: all .2s ease
}

.ace-thumbnails>li>.tools.tools-right {
    left: auto;
    right: -30px
}

.ace-thumbnails>li>.tools.tools-bottom {
    width: auto;
    height: 28px;
    left: 0;
    right: 0;
    top: auto;
    bottom: -30px
}

.ace-thumbnails>li>.tools.tools-top {
    width: auto;
    height: 28px;
    left: 0;
    right: 0;
    top: -30px;
    bottom: auto
}

.ace-thumbnails>li:hover>.tools {
    left: 0;
    right: 0
}

.ace-thumbnails>li:hover>.tools.tools-bottom {
    top: auto;
    bottom: 0
}

.ace-thumbnails>li:hover>.tools.tools-top {
    bottom: auto;
    top: 0
}

.ace-thumbnails>li:hover>.tools.tools-right {
    left: auto;
    right: 0
}

.ace-thumbnails>li>.in.tools {
    left: 0;
    right: 0
}

.ace-thumbnails>li>.in.tools.tools-bottom {
    top: auto;
    bottom: 0
}

.ace-thumbnails>li>.in.tools.tools-top {
    bottom: auto;
    top: 0
}

.ace-thumbnails>li>.in.tools.tools-right {
    left: auto;
    right: 0
}

.ace-thumbnails>li>.tools>a,
.ace-thumbnails>li>:first-child .inner a {
    display: inline-block;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    padding: 0 4px
}

.ace-thumbnails>li>.tools>a:hover,
.ace-thumbnails>li>:first-child .inner a:hover {
    text-decoration: none;
    color: #C9E2EA
}

.ace-thumbnails>li .tools.tools-bottom>a,
.ace-thumbnails>li .tools.tools-top>a {
    display: inline-block
}

.ace-thumbnails>li>:first-child>.text {
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    color: #FFF;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .2s ease
}

.dialogs,
.itemdiv {
    position: relative
}

.ace-thumbnails>li>:first-child>.text:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0
}

.ace-thumbnails>li>:first-child>.text>.inner {
    padding: 4px 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    max-width: 90%
}

.ace-thumbnails>li:hover>:first-child>.text {
    opacity: 1;
    filter: alpha(opacity=100)
}

@media only screen and (max-width:480px) {
    .ace-thumbnails {
        text-align: center
    }
    .ace-thumbnails>li {
        float: none;
        display: inline-block
    }
}


.tab-content {
    border: 1px solid #C5D0DC;
    padding: 16px 12px;
    position: relative
}

.tab-content.no-padding {
    padding: 0
}

.tab-content.no-border {
    border: none;
    padding: 12px
}

.tab-content.padding-32 {
    padding: 32px 24px
}

.tab-content.no-border.padding-32 {
    padding: 32px
}

.tab-content.padding-30 {
    padding: 30px 23px
}

.tab-content.no-border.padding-30 {
    padding: 30px
}

.tab-content.padding-28 {
    padding: 28px 21px
}

.tab-content.no-border.padding-28 {
    padding: 28px
}

.tab-content.padding-26 {
    padding: 26px 20px
}

.tab-content.no-border.padding-26 {
    padding: 26px
}

.tab-content.padding-24 {
    padding: 24px 18px
}

.tab-content.no-border.padding-24 {
    padding: 24px
}

.tab-content.padding-22 {
    padding: 22px 17px
}

.tab-content.no-border.padding-22 {
    padding: 22px
}

.tab-content.padding-20 {
    padding: 20px 15px
}

.tab-content.no-border.padding-20 {
    padding: 20px
}

.tab-content.padding-18 {
    padding: 18px 14px
}

.tab-content.no-border.padding-18 {
    padding: 18px
}

.tab-content.padding-16 {
    padding: 16px 12px
}

.tab-content.no-border.padding-16 {
    padding: 16px
}

.tab-content.padding-14 {
    padding: 14px 11px
}

.tab-content.no-border.padding-14 {
    padding: 14px
}

.tab-content.padding-12 {
    padding: 12px 9px
}

.tab-content.no-border.padding-12 {
    padding: 12px
}

.tab-content.padding-10 {
    padding: 10px 8px
}

.tab-content.no-border.padding-10 {
    padding: 10px
}

.tab-content.padding-8 {
    padding: 8px 6px
}

.tab-content.no-border.padding-8 {
    padding: 8px
}

.tab-content.padding-6 {
    padding: 6px 5px
}

.tab-content.no-border.padding-6 {
    padding: 6px
}

.tab-content.padding-4 {
    padding: 4px 3px
}

.tab-content.no-border.padding-4 {
    padding: 4px
}

.tab-content.no-border.padding-2,
.tab-content.padding-2 {
    padding: 2px
}

.tab-content.no-border.padding-0,
.tab-content.padding-0 {
    padding: 0
}


.nav.nav-tabs.padding-28 {
    padding-left: 28px
}

.tabs-left>.nav.nav-tabs.padding-28,
.tabs-right>.nav.nav-tabs.padding-28 {
    padding-left: 0;
    padding-top: 28px
}

.nav.nav-tabs.padding-26 {
    padding-left: 26px
}

.tabs-left>.nav.nav-tabs.padding-26,
.tabs-right>.nav.nav-tabs.padding-26 {
    padding-left: 0;
    padding-top: 26px
}

.nav.nav-tabs.padding-24 {
    padding-left: 24px
}

.tabs-left>.nav.nav-tabs.padding-24,
.tabs-right>.nav.nav-tabs.padding-24 {
    padding-left: 0;
    padding-top: 24px
}

.nav.nav-tabs.padding-22 {
    padding-left: 22px
}

.tabs-left>.nav.nav-tabs.padding-22,
.tabs-right>.nav.nav-tabs.padding-22 {
    padding-left: 0;
    padding-top: 22px
}

.nav.nav-tabs.padding-20 {
    padding-left: 20px
}

.tabs-left>.nav.nav-tabs.padding-20,
.tabs-right>.nav.nav-tabs.padding-20 {
    padding-left: 0;
    padding-top: 20px
}

.nav.nav-tabs.padding-18 {
    padding-left: 18px
}

.tabs-left>.nav.nav-tabs.padding-18,
.tabs-right>.nav.nav-tabs.padding-18 {
    padding-left: 0;
    padding-top: 18px
}

.nav.nav-tabs.padding-16 {
    padding-left: 16px
}

.tabs-left>.nav.nav-tabs.padding-16,
.tabs-right>.nav.nav-tabs.padding-16 {
    padding-left: 0;
    padding-top: 16px
}

.nav.nav-tabs.padding-14 {
    padding-left: 14px
}

.tabs-left>.nav.nav-tabs.padding-14,
.tabs-right>.nav.nav-tabs.padding-14 {
    padding-left: 0;
    padding-top: 14px
}

.nav.nav-tabs.padding-12 {
    padding-left: 12px
}

.tabs-left>.nav.nav-tabs.padding-12,
.tabs-right>.nav.nav-tabs.padding-12 {
    padding-left: 0;
    padding-top: 12px
}

.nav.nav-tabs.padding-10 {
    padding-left: 10px
}

.tabs-left>.nav.nav-tabs.padding-10,
.tabs-right>.nav.nav-tabs.padding-10 {
    padding-left: 0;
    padding-top: 10px
}

.nav.nav-tabs.padding-8 {
    padding-left: 8px
}

.tabs-left>.nav.nav-tabs.padding-8,
.tabs-right>.nav.nav-tabs.padding-8 {
    padding-left: 0;
    padding-top: 8px
}

.nav.nav-tabs.padding-6 {
    padding-left: 6px
}

.tabs-left>.nav.nav-tabs.padding-6,
.tabs-right>.nav.nav-tabs.padding-6 {
    padding-left: 0;
    padding-top: 6px
}

.nav.nav-tabs.padding-4 {
    padding-left: 4px
}

.tabs-left>.nav.nav-tabs.padding-4,
.tabs-right>.nav.nav-tabs.padding-4 {
    padding-left: 0;
    padding-top: 4px
}

.nav.nav-tabs.padding-2 {
    padding-left: 2px
}

.tabs-left>.nav.nav-tabs.padding-2,
.tabs-right>.nav.nav-tabs.padding-2 {
    padding-left: 0;
    padding-top: 2px
}

.nav-tabs {
    border-color: #C5D0DC;
    margin-bottom: 0!important;
    position: relative;
    top: 1px
}

.nav-tabs>li>a {
    padding: 7px 12px 8px
}

.nav-tabs>li>a,
.nav-tabs>li>a:focus {
    border-radius: 0!important;
    border-color: #C5D0DC;
    background-color: #F9F9F9;
    color: #999;
    margin-right: -1px;
    line-height: 18px;
    position: relative
}

.nav-tabs>li>a:hover {
    background-color: #FFF;
    color: #4C8FBD;
    border-color: #C5D0DC
}

.nav-tabs>li>a:active,
.nav-tabs>li>a:focus {
    outline: 0!important
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #576373;
    border-color: #C5D0DC #C5D0DC transparent;
    border-top: 2px solid #4C8FBD;
    background-color: #FFF;
    z-index: 1;
    line-height: 18px;
    margin-top: -1px;
    box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, .15)
}

.tabs-below>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    margin-top: -1px;
    border-color: #C5D0DC;
    border-bottom-width: 0
}

.tabs-below>.nav-tabs>li>a,
.tabs-below>.nav-tabs>li>a:focus,
.tabs-below>.nav-tabs>li>a:hover {
    border-color: #C5D0DC
}

.tabs-below>.nav-tabs>li.active>a,
.tabs-below>.nav-tabs>li.active>a:focus,
.tabs-below>.nav-tabs>li.active>a:hover {
    border-color: transparent #C5D0DC #C5D0DC;
    border-top-width: 1px;
    border-bottom: 2px solid #4C8FBD;
    margin-top: 0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .15)
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
    min-width: 60px
}

.tabs-left>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #C5D0DC;
    float: left
}

.tabs-left>.nav-tabs>li {
    float: none!important
}

.tabs-left>.nav-tabs>li>a,
.tabs-left>.nav-tabs>li>a:focus,
.tabs-left>.nav-tabs>li>a:hover {
    border-color: #C5D0DC;
    margin: 0 -1px 0 0
}

.tabs-left>.nav-tabs>li.active>a,
.tabs-left>.nav-tabs>li.active>a:focus,
.tabs-left>.nav-tabs>li.active>a:hover {
    border-color: #C5D0DC transparent #C5D0DC #C5D0DC;
    border-top-width: 1px;
    border-left: 2px solid #4C8FBD;
    margin: 0 -1px;
    box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .15)!important
}

.tabs-right>.nav-tabs {
    top: auto;
    margin-bottom: 0;
    border-color: #C5D0DC;
    float: right
}

.tabs-right>.nav-tabs>li {
    float: none!important
}

.tabs-right>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li>a:hover {
    border-color: #C5D0DC;
    margin: 0 -1px
}

.tabs-right>.nav-tabs>li.active>a,
.tabs-right>.nav-tabs>li.active>a:focus,
.tabs-right>.nav-tabs>li.active>a:hover {
    border-color: #C5D0DC #C5D0DC #C5D0DC transparent;
    border-top-width: 1px;
    border-right: 2px solid #4C8FBD;
    margin: 0 -2px 0 -1px;
    box-shadow: 2px 0 3px 0 rgba(0, 0, 0, .15)
}

.nav-tabs>li>a .badge {
    padding: 1px 5px;
    line-height: 15px;
    opacity: .75;
    vertical-align: baseline;
    vertical-align: initial
}

.nav-tabs>li>a .ace-icon {
    opacity: .75
}

.nav-tabs>li.active>a .ace-icon,
.nav-tabs>li.active>a .badge {
    opacity: 1
}

.nav-tabs li .ace-icon {
    width: 1.25em;
    display: inline-block;
    text-align: center
}

.nav-tabs>li.open .dropdown-toggle {
    background-color: #4F99C6;
    border-color: #4F99C6;
    color: #FFF
}

.nav-tabs>li.open .dropdown-toggle>.ace-icon {
    color: #FFF!important
}

.tabs-left .tab-content,
.tabs-right .tab-content {
    overflow: auto
}

.dark {
    color: #333!important
}

.white {
    color: #FFF!important
}

.red {
    color: #DD5A43!important
}

.red2 {
    color: #E08374!important
}

.light-red {
    color: #F77!important
}

.blue {
    color: #478FCA!important;
    display: flex;
}

.blue span{
    margin-right: 20px;
}

.light-blue {
    color: #93CBF9!important
}

.green {
    color: #69AA46!important
}

.light-green {
    color: #B0D877!important
}

.orange {
    color: #FF892A!important
}

.orange2 {
    color: #FEB902!important
}

.light-orange {
    color: #FCAC6F!important
}

.purple {
    color: #A069C3!important
}

.pink {
    color: #C6699F!important
}

.pink2 {
    color: #D6487E!important
}

.brown {
    color: brown!important
}

.grey {
    color: #777!important
}
.form-control{
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom:1px solid #ced4da !important;
}

.student-details-wrapper, .educational-qualificaitons{
    background-color: #efefef;
}

.align-in-div{
    width: 100% !important;
    margin-right: 15px;
    margin-left: 15px;
}

.form-control.nb{
    border:none !important;
    width: 100%;
}

.register-online-form-div-wrapper .p-2.mx-3.container{
    margin:0 auto !important;
}

.ribbon {
    background-color:#e0b04a;
}

.ribbon p{
    color: white;
    font-size: 1.2em;
    display: inline-flex;
}


.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
transform: rotate(-135deg);
-webkit-transform: rotate(-135deg);
}

.down {
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
}

.ribbon{
    cursor: pointer;
}

.table-body{
    width: 100% !important;
}

.form-table{
    width: 60% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.form-nav-wrapper{
    margin: 0 auto;
}

.fl-di{
    flex-direction: column;
}

.b-r::after{
    content: "/";
    position: relative;
    right: -10px;
}

.table-title a {
    cursor: pointer;
}

.table-title a:hover, .table-title a:active {
    text-decoration: underline !important;
}

.form-nav-wrapper-one, .form-nav-wrapper-two{
    display: inline-flex;
}

.active-class-name {
    color: #4c5a7d;
    font-weight: 600;
    text-decoration: underline !important;
}

.inputFieldError {
    border: 1px solid red !important;
}

.inputFieldSuccess {
    border: 1px solid green !important;
}

@media screen and (max-width:770px){
    .table-body .text-right {
        text-align: left !important;
    }

    .form-table{
        width: 100% !important;
        padding: 1em;
    }

    .form-nav-wrapper {
        flex-direction: column;
        align-items: center;
    }
}
/* ==========================================================================
   #FONT
   ========================================================================== */
.font-robo {
  font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
}

.font-poppins {
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
}

/* ==========================================================================
     #GRID
     ========================================================================== */
.row {
  display: flex;
  flex-wrap: wrap;
}

.row-space {
  justify-content: space-between;
}

.col-2 {
  width: calc((100% - 30px) / 2);
}

@media (max-width: 767px) {
  .col-2 {
    width: 100%;
  }
}

/* ==========================================================================
     #BOX-SIZING
     ========================================================================== */
/**
   * More sensible default box-sizing:
   * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
   */
html {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ==========================================================================
     #RESET
     ========================================================================== */
/**
   * A very simple reset that sits on top of Normalize.css.
   */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
   * Remove trailing margins from nested lists.
   */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
   * Remove default table spacing.
   */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
   * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
   *    on fieldsets.
   */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

button {
  outline: none;
  background: none;
  border: none;
}

/* ==========================================================================
     #PAGE WRAPPER
     ========================================================================== */
.page-wrapper {
  min-height: 100vh;
}

body {
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 13px;
}

/* ==========================================================================
     #BACKGROUND
     ========================================================================== */
.bg-blue {
  background: #2c6ed5;
}

.bg-red {
  background: #fa4251;
}

.bg-gra-01 {
  background: linear-gradient(to top, #fbc2eb 0%, #a18cd1 100%);
}

.bg-gra-02 {
  background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%);
}

/* ==========================================================================
     #SPACING
     ========================================================================== */
.p-t-100 {
  padding-top: 100px;
}

.p-t-130 {
  padding-top: 130px;
}

.p-t-180 {
  padding-top: 180px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.m-r-45 {
  margin-right: 45px;
}

/* ==========================================================================
     #WRAPPER
     ========================================================================== */
.wrapper {
  margin: 0 auto;
}

.wrapper--w960 {
  max-width: 960px;
}

.wrapper--w780 {
  max-width: 780px;
}

.wrapper--w680 {
  max-width: 680px;
}

/* ==========================================================================
     #BUTTON
     ========================================================================== */
/* .btn {
  display: inline-block;
  line-height: 50px;
  padding: 0 50px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: pointer;
  font-size: 18px;
  color: #fff;
  font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
}

.btn--radius {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.btn--radius-2 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.btn--pill {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.btn--green {
  background: #57b846;
}

.btn--green:hover {
  background: #4dae3c;
}

.btn--blue {
  background: #4272d7;
}

.btn--blue:hover {
  background: #3868cd;
} */

/* ==========================================================================
     #DATE PICKER
     ========================================================================== */
td.active {
  background-color: #2c6ed5;
}

input[type="date" i] {
  padding: 14px;
}

.table-condensed td,
.table-condensed th {
  font-size: 14px;
  font-family: "Roboto", "Arial", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.daterangepicker td {
  width: 40px;
  height: 30px;
}

.daterangepicker {
  border: none;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  display: none;
  border: 1px solid #e0e0e0;
  margin-top: 5px;
}

.daterangepicker::after,
.daterangepicker::before {
  display: none;
}

.daterangepicker thead tr th {
  padding: 10px 0;
}

.daterangepicker .table-condensed th select {
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px;
  outline: none;
}

/* ==========================================================================
     #FORM
     ========================================================================== */
/* input {
  outline: none;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
} */

.input--style-4 {
  line-height: 50px;
  background: #fafafa;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  color: #666;
  transition: all 0.4s ease;
}

.input--style-4::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666;
}

.input--style-4:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  opacity: 1;
}

.input--style-4::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  opacity: 1;
}

.input--style-4:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
}

.input--style-4:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666;
}

.label {
  font-size: 16px;
  color: #555;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container input:checked ~ .checkmark {
  background-color: #e5e5e5;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #57b846;
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e5e5e5;
  border-radius: 50%;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-group {
  position: relative;
  margin-bottom: 22px;
}

.input-group-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  font-size: 18px;
  color: #999;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* ==========================================================================
     #SELECT2
     ========================================================================== */
.select--no-search .select2-search {
  display: none !important;
}

.rs-select2 .select2-container {
  width: 100% !important;
  outline: none;
  background: #fafafa;
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.rs-select2 .select2-container .select2-selection--single {
  outline: none;
  border: none;
  height: 50px;
  background: transparent;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 50px;
  padding-left: 0;
  color: #555;
  font-size: 16px;
  font-family: inherit;
  padding-left: 22px;
  padding-right: 50px;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.rs-select2
  .select2-container
  .select2-selection--single
  .select2-selection__arrow:after {
  font-family: "Material-Design-Iconic-Font";
  content: "\f2f9";
  font-size: 24px;
  color: #999;
  transition: all 0.4s ease;
}

.rs-select2
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow::after {
  transform: rotate(-180deg);
}

.select2-container--open .select2-dropdown--below {
  border: none;
  border-radius: 3px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  margin-top: 5px;
  overflow: hidden;
}

.select2-container--default .select2-results__option {
  padding-left: 22px;
}

/* ==========================================================================
     #TITLE
     ========================================================================== */
.title {
  font-size: 24px;
  color: #525252;
  font-weight: 400;
  margin-bottom: 40px;
}

/* ==========================================================================
     #CARD
     ========================================================================== */
.card {
  border-radius: 3px;
  background: #fff;
}

.card-4 {
  width: 65%;
  margin-left: 15%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

.card-4 .card-body {
  padding: 57px 65px;
  padding-bottom: 65px;
}

@media (max-width: 992px) {
  .card-4 {
    width: 100%;
    margin-left: 0%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  }

  .card-4 .card-body {
    padding: 50px 40px;
  }
}

.register-wrapper {
    width: 100% !important;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.register-div {
    width: 60%;
    border: 3px solid #f5f5ff;
    background-color: #f5f5ff;
    padding-bottom: 3em;
    margin-top: 3em;
    margin-bottom: 3em;
}

#patternDiv{
    position: absolute;
    top: 5px;
    right: 12px;
    z-index:20000000000000000000000000000000000;
}
body {
  margin: 0;
}
.page-content {
  width: 100%;
  margin: 0 auto;
  background: #75e2e9;
  /* background-image: url("../../../public/images/author.jpg"); */
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
}
.form-v4-content {
  background: #fff;
  width: 850px;
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin: 100px 0;
  position: relative;
  display: flex;
  display: -webkit-flex;
  font-family: "Open Sans", sans-serif;
}
.form-v4-content h2 {
  font-weight: 700;
  font-size: 30px;
  padding: 6px 0 0;
  margin-bottom: 34px;
  color: #ffffff;
}
.form-v4-content .form-left {
  background: #eea412;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 40px;
  position: relative;
  width: 100%;
  color: #fff;
}
.form-v4-content .form-left p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.5;
  color: #ffffff;
}
.form-v4-content .form-left span {
  font-weight: 700;
}
.form-v4-content .form-left .text-2 {
  margin: 20px 0 25px;
}
.form-v4-content .form-left .account {
  background: #fff;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 180px;
  border: none;
  margin: 15px 0 50px 0px;
  cursor: pointer;
  color: #333;
  font-weight: 700;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  appearance: unset;
  -moz-appearance: unset;
  -webkit-appearance: unset;
  -o-appearance: unset;
  -ms-appearance: unset;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -o-outline: none;
  -ms-outline: none;
}
.form-v4-content .form-left .account:hover {
  background: #e5e5e5;
}
.form-v4-content .form-left .form-left-last input {
  padding: 15px;
}
.form-v4-content .form-detail {
  padding: 20px 40px;
  position: relative;
  width: 100%;
}
.form-v4-content .form-detail h2 {
  color: #3786bd;
}
.form-v4-content .form-detail .form-group {
  display: flex;
  display: -webkit-flex;
  margin: 0 -8px;
}
.form-v4-content .form-detail .form-row {
  width: 100%;
  position: relative;
}
.form-v4-content .form-detail .form-group .form-row.form-row-1 {
  width: 50%;
  padding: 0 8px;
}
.form-v4-content .form-detail label {
  font-weight: 600;
  font-size: 15px;
  color: #3e3636;
  display: block;
  margin-bottom: 8px;
}
.form-v4-content .form-detail .form-row label#valid {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #53c83c;
}
.form-v4-content .form-detail .form-row label#valid::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.form-v4-content .form-detail .form-row label.error {
  padding-left: 0;
  margin-left: 0;
  display: block;
  position: absolute;
  bottom: -5px;
  width: 100%;
  background: none;
  color: red;
}
.form-v4-content .form-detail .form-row label.error::after {
  content: "\f343";
  font-family: "LineAwesome";
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  right: 10px;
  top: -31px;
  color: red;
  font-size: 18px;
  font-weight: 900;
}
.form-v4-content .form-detail .input-text {
  margin-bottom: 27px;
}
.form-v4-content .form-detail input {
  width: 100%;
  padding: 11.5px 15px;
  border: 1px solid #e5e5e5;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  appearance: unset;
  -moz-appearance: unset;
  -webkit-appearance: unset;
  -o-appearance: unset;
  -ms-appearance: unset;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -o-outline: none;
  -ms-outline: none;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  color: #333;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.form-v4-content .form-detail .form-row input:focus {
  border: 1px solid #53c83c;
}
.form-v4-content .form-detail .form-checkbox {
  margin-top: 1px;
  position: relative;
}
.form-v4-content .form-detail .form-checkbox input {
  position: absolute;
  opacity: 0;
}
.form-v4-content .form-detail .form-checkbox .checkmark {
  position: absolute;
  top: 13px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.form-v4-content .form-detail .form-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: 1px solid #3786bd;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}
.form-v4-content .form-detail .form-checkbox input:checked ~ .checkmark::after {
  display: block;
}
.form-v4-content .form-detail .form-checkbox p {
  margin-left: 34px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}

.form-control-teacher {
  border: 1px solid #ced4da !important;
}
.form-v4-content .form-detail .form-checkbox .text {
  font-weight: 700;
  color: #3786bd;
  text-decoration: underline;
}
.form-v4-content .form-detail .register {
  background: #3786bd;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 130px;
  border: none;
  margin: 6px 0 50px 0px;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
}
.form-v4-content .form-detail .register:hover {
  background: #2f73a3;
}
.form-v4-content .form-detail .form-row-last input {
  padding: 12.5px;
}

::-webkit-input-placeholder {
  color: #d8d6d5;
}

:-ms-input-placeholder {
  color: #d8d6d5;
}

::-ms-input-placeholder {
  color: #d8d6d5;
}

::placeholder {
  color: #d8d6d5;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .form-v4-content {
    margin: 180px 20px;
    flex-direction: column;
    -o-flex-direction: column;
    -moz-flex-direction: column;
  }
  .form-v4-content .form-left {
    width: auto;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
  .form-v4-content .form-detail {
    padding: 30px 20px 30px 20px;
    width: auto;
  }
}
@media screen and (max-width: 575px) {
  .form-v4-content .form-detail .form-group {
    flex-direction: column;
    -o-flex-direction: column;
    -moz-flex-direction: column;
    margin: 0;
  }
  .form-v4-content .form-detail .form-group .form-row.form-row-1 {
    width: 100%;
    padding: 0;
  }
}

