.form-control{
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom:1px solid #ced4da !important;
}

.student-details-wrapper, .educational-qualificaitons{
    background-color: #efefef;
}

.align-in-div{
    width: 100% !important;
    margin-right: 15px;
    margin-left: 15px;
}

.form-control.nb{
    border:none !important;
    width: 100%;
}

.register-online-form-div-wrapper .p-2.mx-3.container{
    margin:0 auto !important;
}

.ribbon {
    background-color:#e0b04a;
}

.ribbon p{
    color: white;
    font-size: 1.2em;
    display: inline-flex;
}


.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.up {
transform: rotate(-135deg);
-webkit-transform: rotate(-135deg);
}

.down {
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
}

.ribbon{
    cursor: pointer;
}

.table-body{
    width: 100% !important;
}

.form-table{
    width: 60% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.form-nav-wrapper{
    margin: 0 auto;
}

.fl-di{
    flex-direction: column;
}

.b-r::after{
    content: "/";
    position: relative;
    right: -10px;
}

.table-title a {
    cursor: pointer;
}

.table-title a:hover, .table-title a:active {
    text-decoration: underline !important;
}

.form-nav-wrapper-one, .form-nav-wrapper-two{
    display: inline-flex;
}

.active-class-name {
    color: #4c5a7d;
    font-weight: 600;
    text-decoration: underline !important;
}

.inputFieldError {
    border: 1px solid red !important;
}

.inputFieldSuccess {
    border: 1px solid green !important;
}

@media screen and (max-width:770px){
    .table-body .text-right {
        text-align: left !important;
    }

    .form-table{
        width: 100% !important;
        padding: 1em;
    }

    .form-nav-wrapper {
        flex-direction: column;
        align-items: center;
    }
}