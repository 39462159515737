.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item p {
  width: 80%;
  font-size: 17px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #5d5c5c;
}

.branchName {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #ffad00;
  text-decoration: underline;
}

.icon {
  padding: 4px;
  background-color: #ffad00;
  border-radius: 50%;
  color: white;
  margin: 20px;
  width: 35px;
  height: 35px;
  text-align: center;
  align-items: center;
  display: inline-block;
}
