.login-div {
    text-align: center;
    padding: 5px;
}

#popup-div > div > div > div {
    /* use transparent insted for complete transparent background */
    background-color: rgba(239, 238, 238, 0.5) !important;
}

.hide {
    display: none;
}

.wrapper{
    width: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: auto;
    margin: -4em auto !important;
}

.login-div form {
    margin-top: 5em !important;
}

.close-button{
    position: relative;
    right: 20px;
    top: 0;
    font-size: 20px;
    font-weight: bold;
    color: #e65349;
    cursor: pointer;
    float: right;
    z-index: 20000000;
}

.close-button:hover{
    color: #f31d0f;
}

@media screen and (max-width : 600px){
    .wrapper{
        width: 80%;
    }
}

.welcome-wrapper h2{
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    font-weight: 500;
}

.welcome-wrapper i {
    font-size: 7em;
    background-color: white;
    border-radius: 50%;
    padding: 7px;
    color: #546286;
}

.welcome-wrapper{
    margin: 30px;
}


.input-field-wrapper{
    position: relative;
    margin: 30px;
}

.input-field-wrapper input{
    border: none;
    border-bottom: 1px solid gray;
    background:none;
    z-index: 20;
    position: relative;
}

#userName:active, #userName:focus ~ .lable{
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#pwd:active, #pwd:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#firstName:active, #firstName:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#lastName:active, #lastName:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#uName:active, #uName:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#password:active, #password:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#cpassword:active, #cpassword:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#mobileNumber:active, #mobileNumber:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

input[value]:not([value=""]) ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

.lable{
    position: absolute;
    top:50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 2;
}

@keyframes movetop {
    from{
        top: 50%;
    }
    to {
        top: -15px;
    }
}

.submit-wrapper {
    padding-left: 30px;
    padding-right: 30px;
}

.submit-wrapper label{
    float: left;
}

.submit-wrapper button {
    float: right;
}

.inputFieldError {
    border-bottom: 4px solid red !important;
}

.inputFieldSuccess{
    border-bottom: 1px solid green !important;
}

.form-control:active, .form-control:focus{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.login-btn{
    float: right;
}

.login-wrapper {
    background-color: white;
    border-radius: 1rem;
    margin-top: -92px;
    min-height: 280px;
}

.become-member {
    width: 100%;
    padding: 0;
    height: auto;
    position: relative;
    display: inline-block;
}

.register-wrapper-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.register-wrapper-header img {
    height: 35px;
}

.teacher-sample-demo{
    display: none;
}

.our-team:hover .teacher-sample-demo{
    display:block;
}

.request-demo-form-wrapper {
    width: 35%;
    height: auto;
    background: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
}

/* for Requestdemo input field animation */

#reqFullName:active, #reqFullName:focus ~ .lable{
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#reqEmail:active, #reqEmail:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#reqMobileNumber:active, #reqMobileNumber:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

#reqCourses:active, #reqCourses:focus ~ .lable {
    top: -15px;
    animation: movetop;
    animation-duration: 500ms;
}

/* remove previous and next buttons on home carosal */

@media screen and (max-width: 640px){
    .new-effect {
        display: none !important;
    }
}

.entities{
    color: red;
    padding: 0 5px;
    border: 1px solid red;
    margin: 0 -3px;
    border-radius: 50%;
}

.entitiesVallid {
    border: 1px solid green !important;
    color: green !important;
}

