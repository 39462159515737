.main-wrapper {
    position: relative;
    top: 42px;
    height: auto;
    margin-top: 64px;
    margin-bottom: 64px;
}

.welcome-wrapper{
    text-align: center;
}

.welcome-wrapper img{
    margin: 0 auto;
}

.wrapper-div{
    width: 50%;
    margin: 0 auto;
}

.form-control:focus{
    box-shadow: none;
}